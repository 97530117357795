import React, { useEffect, useMemo, useState } from 'react'

import 'core/aggrid'
import './locales/packages'
import './theme'
import './core/dayjs'
import './i18n'

import { ApolloProvider } from '@apollo/client'
import { notification } from 'antd'
import Highcharts from 'highcharts'
import { createBrowserHistory } from 'history'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import ReactDOM from 'react-dom'
import Helmet from 'react-helmet'
import { useTheme } from 'theme/hooks'

import { appName } from 'config'
import { HIGHCHARTS_DEFAULT_COLOR_SCHEME } from 'util/constants'
import WebsocketContextProvider from 'contexts/WebsocketContext'
import { client } from 'services/apollo'

import { NotificationProvider } from 'components/Layout/Notifications'
import AppUiProvider from 'components/Layout/UiProvider'

import Localization from './components/LayoutComponents/Localization'
import { forceLogoutUsersBeforeTime } from './core/token'
import Router from './router'
import { SocketProvider, Store } from './services/store'
// import GlobalStyled from './theme/styled.global'

if (['demo', 'prod'].includes(process.env.REACT_APP_ENV)) {
  LogRocket.init('soignm/aviary')
  setupLogRocketReact(LogRocket)
}

const history = createBrowserHistory()

Highcharts.setOptions({
  colors: HIGHCHARTS_DEFAULT_COLOR_SCHEME,
})

const Aviary = () => {
  const forceLogoutUser = async (showMessage = false, redirect = true) => {
    localStorage.removeItem('token')
    localStorage.removeItem('customer')
    localStorage.removeItem('theme')
    localStorage.removeItem('user')
    localStorage.removeItem('helpdeskInProgress')
    client.clearStore().then(() => {
      if (showMessage) {
        notification.error({
          message: 'Authentication error',
          description: 'You were logged out by the system, please sign in again.',
          duration: 0,
        })
      }
      if (redirect) {
        history.replace('/user/login')
      }
    })
  }

  const customTheme = useTheme(forceLogoutUser)

  useEffect(() => {
    if (customTheme) {
      const faviconEl = document.getElementById('favicon')
      faviconEl.href = customTheme.images.favicon
    }
  }, [customTheme])

  const changeGlobalTime = (startTime, endTime) => {
    setGlobalTime({
      ...globalTime,
      startTime,
      endTime,
    })
  }

  // Dark mode force logout
  const thresholdTime = 1647356400 // dinsdag 15 maart 2022 16:00:00 GMT+01:00
  forceLogoutUsersBeforeTime(thresholdTime, forceLogoutUser)

  const [globalTime, setGlobalTime] = useState({
    startTime: null,
    endTime: null,
    changeGlobalTime,
  })

  const _images = customTheme?.images

  const theme = useMemo(
    () => ({
      ...customTheme,
      images: _images,
    }),
    [customTheme, _images]
  )

  return !customTheme ? null : (
    <Store apolloClient={client} logoutUser={forceLogoutUser}>
      <ApolloProvider client={client}>
        <WebsocketContextProvider socket={SocketProvider}>
          <Localization>
            <AppUiProvider theme={theme}>
              <Helmet title={appName}>
                {process.env.REACT_APP_CURRENT_GIT_SHA && (
                  <meta name="version" content={process.env.REACT_APP_CURRENT_GIT_SHA} />
                )}
              </Helmet>
              <NotificationProvider socket={SocketProvider}>
                <Router history={history} />
              </NotificationProvider>
              {/* <GlobalStyled /> */}
            </AppUiProvider>
          </Localization>
        </WebsocketContextProvider>
      </ApolloProvider>
    </Store>
  )
}

ReactDOM.render(<Aviary />, document.getElementById('root'))

export { history }
