export default {
  all: 'Toute',
  active: 'Active',
  hideInactive: 'Cacher les inactifs',
  action: 'Action',
  clickToCopy: 'Cliquez pour copier',
  copiedToClipboard: 'La valeur est copié dans votre presse-papiers',
  duplicate: 'Dupliquer',
  deepDuplicate: 'Dupliquer arborescence',
  moveTo: 'Déménager à',
  remarks: 'Remarques',
  delete: 'Supprimer',
  confirm: 'Confirmer',
  save: 'Sauver',
  clear: 'Dégager',
  cancel: 'Annuler',
  continue: 'Continuer',
  enabled: 'Activée',
  enable: 'Activer',
  disable: 'Désactivée',
  create: 'Créer',
  deleteSuccess: '{name} a été supprimé avec succès',
  isRequired: ' est obligatoire',
  confirmMessage: 'Es-tu sûr?',
  confirmVehicleDisable: 'Êtes-vous sûr de vouloir désactiver le véhicule?',
  deleteConfirmationMessage: 'Êtes-vous sûr de supprimer ',
  unsubscribeConfirmationMessage: 'Êtes-vous sûr de vouloir vous désinscrire du groupe ',
  deleteRenewMessage: 'Êtes-vous sûr de ré-inviter ',
  deleteItemSuccess: "L'article est supprimé",
  deleteItemError: "Impossible de supprimer l'élément",
  deleteItemViolationError: "Impossible de supprimer l'élément. Il est lié à d'autres éléments",
  duplicateConfirmationMessage: 'Êtes-vous sûr de vouloir dupliquer',
  duplicateItemSuccess: "L'élément a été dupliqué",
  duplicateItemError: "Impossible de dupliquer l'élément",
  duplicateItemStart: 'La duplication de {name} a démarré',
  updateSuccess: ' a été modifié avec succès',
  updateItemSuccess: 'Article sauvegardé avec succès',
  updateItemError: "Impossible d'enregistrer l'article",
  insertItemSuccess: 'Article créé avec succès',
  insertItemError: "Impossible de créer l'article",
  pasteItemSuccess: 'Collé {name} avec succès',
  pasteItemError: `Quelque chose s'est mal passé en collant {name}`,
  confirmDiscardChanges: 'Êtes-vous sûr de vouloir supprimer vos modifications?',
  selected: 'Choisie',
  loadnig: 'Chargement',
  multiply: 'Multiplier',
  devide: 'Dévier',
  copyFfDefaults: 'Importer valeurs par default',
  data: 'Données',
  historical: 'Historical',
  transparancy: 'Transparence',
  benchmark: 'Référence',
  date: 'Date',
  activationDate: `Date d'activation`,
  export: 'Export',
  export_xlsx: 'Export XLSX',
  export_docx: 'Export DOCX',
  site: 'Placer',
  building: 'Imeuble',
  country: 'Pays',
  department: 'Département',
  dateOfBirth: 'Date de naissance',
  yearOfBirth: 'Année de naissance',
  gender: 'Genre',
  highestDegreeOfEducation: "Highest degré d'éducation",
  filter: 'Filtre',
  clearFilter: 'Effacer le filtre',
  download: 'Télécharger',
  reload: 'Recharger',
  add: 'Ajouter',
  import: 'Importer',
  vehicle: 'Véhicule',
  vehicleType: 'Type de véhicule',
  power: 'Puissance',
  fuelConsumptionCombined: 'Consommation de carburant combinée',
  energyConsumptionCombined: `Consommation d'énergie combinée`,
  co2Emission: 'Emissions de CO2',
  maxLoadCapacity: 'Capacité de charge maximale',
  fuelType: 'Type de carburant',
  vehicleFuelType: 'Véhicule - Type de carburant',
  organisation: 'Organisation',
  organisationalSturctureType: 'Type de structure organisationnelle',
  entity: 'Entité',
  value: 'Valeur',
  internalId: 'ID interne',
  constructionYear: 'Année de construction',
  consumption: 'Consommation',
  mileage: 'Kilométrage',
  consumptionInputPreference: `Préférence d'entrée de consommation`,
  createNewVersion: 'Créer une nouvelle version',
  title: 'Titre',
  subTitle: 'Sous-titre',
  default: 'Défaut',
  contract: 'Contracter',
  key: 'Clé',
  items: 'Articles',
  description: 'Description',
  order: 'Commande',
  parent: 'Parente',
  label: 'Étiqueter',
  color: 'Couleur',
  page: 'page',
  row: 'Ligne',
  name: 'Name',
  product: 'Produit',
  productionRelated: 'Production liée à la production',
  finishingLevel: 'Niveau de finition',
  supplier: 'Le fournisseur',
  reportingPeriod: 'Période de reporting',
  totalEmission: 'Total des émissions',
  extra: 'Supplémentaire',
  datapoint: 'Point de données',
  settings: 'Réglages',
  equipment: 'Équipement',
  exportAsExcel: 'Exporter vers Excel',
  exportAsWord: 'Exporter vers Word',
  yes: 'Oui',
  no: 'Non',
  lastValue: 'Dernière valeur',
  direction: 'Direction',
  category: 'Catégorie',
  selectCategory: 'Select catégorie',
  consumer: 'Consommatrice',
  activity: 'Activité',
  transportMode: 'Mode de transport',
  transportLoadType: 'Type de charge de transport',
  travelMethod: 'Méthode de voyage',
  typeLogistics: 'Type de logistique',
  image: 'image',
  type: 'Taper',
  createdAt: 'Créé à',
  electMultipleItems: 'Sélectionnez plusieurs éléments',
  duplicateSelected: 'Dupliquer les {count} élément(s) ici',
  moveSelected: 'Déplacer les {count} élément(s) ici',
  bulkDuplicateItemsSuccess: 'Les {count} élément(s) ont été dupliqués',
  bulkMoveItemsSuccess: 'Les {count} élément(s) ont été déplacés',
  maxFavouritesReached: 'Max de {amount} favoris est atteint.',
  inCompleteForm: 'Veuillez remplir tous les champs obligatoirs',
  sortBy: 'Trier par',
  parentOrganisation: 'Organisation parentale',
  childOrganisation: 'Organisation enfant',
  percentage: 'Pourcentage',
  financialControl: 'Contrôle financier',
  jointFinancialControl: 'Contrôle financier conjoint',
  operationalControl: 'Contrôle opérationnel',
  ownership: 'La possession',
  info: 'info',
  joint: 'Découper',
  equityShare: 'Partage équitable',
  equitySharePercentage: 'Partage équitable pourcentage',
  organisationDraftMode:
    'Vous êtes en mode brouillon, sélectionnez une date et enregistrez pour quitter le mode Draft.',
  percentageExceeds: 'Le pourcentage total dépasse {value}%',
  greaterThen: 'Doit être plus grand alors {value}',
  franchisee: 'Franchisee',
  franchisor: 'Franchiseur',
  lessor: 'Bailleur',
  lessee: 'Preneur à bail',
  investor: 'Investisseur',
  investee: 'Entité investie',
  combinationWithFieldAlreadyExists: 'La combinaison avec {field} existe déjà',
  environmentType: "Type d'environnement",
  distributed: 'Distribué',
  sharedOwnership: 'Propriété partagée',
  remark: 'Remarque',
  dateRequired: 'La date est requise',
  totalExceedsAmount: 'Le total dépasse {amount}',
  canNotBeTheSameAs: 'Ne peut pas être le même que {value}',
  details: 'Détails',
  showDetails: 'Afficher les détails',
  consumptionValue: 'Valeur de consommation',
  'consumptionValue.info':
    'Entrer la valeur comme un relevé de compteur (Désactivé) ou comme une valeur de consommation (Activé)',
  locked: 'Verrouillé',
  open: 'Ouverte',
  updateError: 'Impossible de sauver vos modifications',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  period: 'Periode',
  status: 'Statut',
  license: 'Licence',
  noModulesFound: 'Aucun module trouvé',
  unlimited: 'Illimité',
  min: 'Min',
  max: 'Max',
  timestamp: 'Timestamp',
  user: 'Utilisateur',
  operation: 'Action',
  customer: 'Client',
  switchedToCustomer: 'Passer au {customer}',
  application: 'Application',
  version: 'Version',
  versions: 'Versions',
  deployedAt: 'Déployé à',
  shortcuts: 'Raccourcis',
  dashboard: 'Tableau de bord',

  boundaries: 'Limites',
  activities: 'Activités',
  ghgSettings: 'GHG paramètres',
  projectTeam: 'Project team',
  successfullySaved: 'Savegardé.',
  squealThreshold: 'Si % Squeal >',
  sendMail: 'Envoyer e-mail',
  sendSms: 'Envoyer message texte',
  sendVoiceMessage: 'Appeler portable',
  sendVoiceFixedMessage: 'Appeler ligne fixe',
  doNothing: "Pas d'action",
  noFlowLogic: 'Veuillez définier au moindre une condition',
  if: 'Si',
  then: 'Alors',
  elseIf: 'Sinon, si',
  else: 'Autre',
  to: 'à',
  show: 'Montrer',

  'reportingYearLock.update.success': `Le verrouillage de l'année de rapport est mis à jour`,

  'organisation.structure.state.update.success': "L'état est sauvé",
  'organisation.structure.state.create.error':
    "Erreur créant une nouvelle structure d'organisation",
  'organisation.structure.state.update.error':
    "Erreur mise à jour de la structure de l'organisation",
  'organisation.structure.state.update.error.percentage': 'Certains pourcentages dépassent 100%',
  'organisation.structure.state.edge.error.onlyOne': 'Une seule relation possible',

  'organisation.env.state.create.success': 'Un nouvel État de projet est créé',
  'organisation.env.state.create.error':
    "Erreur de création d'un nouvel environnement d'organisation État",
  'organisation.env.state.update.success': "L'état est sauvé",
  'organisation.env.state.update.error': "Erreur mise à jour de la structure de l'organisation",
  'organisation.env.state.update.error.percentage': 'Certains pourcentages dépassent 100%',

  'error.entity.samePresetExistsInSite':
    'Une entité avec le même préréglage existe déjà sur ce site.Une seule entité de ce type est autorisée.',

  'noAccess.title': "Pas d'accès",
  'noAccess.subTitle': "Désolé, vous n'êtes pas autorisé à accéder à cette page.",

  // Entity
  'entity.type.department': 'Département',
  'entity.type.building': 'Bâtiment',
  'entity.type.site': 'Site',
  'entity.type.fuel': 'Le carburant',
  'entity.type.fuelType': 'Type de carburant',

  // Dashboard components
  'calendarComponent.unsavedChange': 'modifications non enregistrées...',
  'calendarComponent.savedChanges': 'modification enregistrées',
  'calendarComponent.save': 'Enregistrer',
  'connectivity.title': 'CONNECTIVITÉ',
  'connectivity.isOnline': 'est en ligne',
  'connectivity.lastConnection': 'dernière connexion, il y a',
  'connectivity.ago': ' ',
  'graphComponent.presetControl': 'Preset',
  'graphComponent.startTimeControl.startTime': 'Heure de début',
  'graphComponent.endTimeControl.endTime': 'Heure de fin',
  'graphComponent.granularyControl.granularity': 'Granularité',
  'graphComponent.presetControl.thisDay': "Aujourd'hui",
  'graphComponent.presetControl.linkedWidget': 'Widget lié',
  'graphComponent.presetControl.thisWeek': 'Cette semaine',
  'graphComponent.presetControl.thisMonth': 'Ce mois',
  'graphComponent.presetControl.thisYear': 'Cette année',
  'graphComponent.presetControl.week': 'Semaine',
  'graphComponent.presetControl.month': 'Mois',
  'graphComponent.presetControl.year': 'Année',
  'graphComponent.presetControl.pastDay': '24 dernières heures',
  'graphComponent.presetControl.past7Days': '7 dernier jours',
  'graphComponent.presetControl.past21Days': '21 derniers jours',
  'graphComponent.presetControl.past28Days': '28 derniers jours',
  'graphComponent.presetControl.previousWeek': 'Semaine passée',
  'graphComponent.presetControl.previousMonth': 'Mois dernier',
  'graphComponent.presetControl.previousYear': 'Année passée',
  'graphComponent.presetControl.yesterday': 'Hier',
  'graphComponent.presetControl.dayBefore': 'Avant hier',
  'graphComponent.presetControl.thisDayLastWeek': 'Ce jour la semaine dernière',
  'graphComponent.presetControl.customPeriod': 'Période personnalisée',
  'graphComponent.presetControl.customRange': 'Gamme personnalisée',
  'graphComponent.presetControl.customDate': 'Date personnalisée',
  'graphComponent.presetControl.none': 'Aucun',
  'graphComponent.presetControl.start': 'Début',
  'graphComponent.presetControl.end': 'Fin',
  'graphComponent.rawDataControl.rawData': 'Données brutes',
  'graphComponent.tooltip.totals': 'Totaux',
  'severityComponent.critical.criticalIssues': 'Problèmes critiques',
  'severityComponent.severe.severeIssues': 'Problèmes graves',
  'severityComponent.warning.warnings': 'Avertissements',
  'severityComponent.details': 'Détails',
  'severityComponent.overview': "Vue d'ensemble",
  'squealHeatmapComponent.heatMap.selectSqueal': 'Sélectionnez squeal pour inspecter',
  'squealOverviewComponent.acknowledge': 'Confirmer',
  'squealOverviewComponent.viewHistory': "Voir l'historique",
  'squealOverviewComponent.startTime': 'Heure de début',
  'squealOverviewComponent.cost': 'Coût',
  'squealOverviewComponent.severity': 'Sévérité',
  'squealOverviewComponent.name': 'Nom',
  'squealOverviewComponent.confirmAcknowledge.title': 'Êtes-vous sûr de vouloir confirmer le cri?',
  'squealOverviewComponent.confirmAcknowledge.subtitle':
    "Il ne s'affichera plus à moins que le dysfonctionnement ne se reproduise.",
  'squealRemarksComponent.cancel': 'Annuler',
  'squealRemarksComponent.confirm': 'Confirmer',
  'squealRemarksComponent.justifyAck': 'Justification de la confirmation du squeal',
  'squealRemarksComponent.nothingToRemark': 'Aucune remarque à faire pour ce squeal.',
  'squealRemarksComponent.remarkInput.period': 'Période',
  'squealRemarksComponent.remarkInput.peakVisitors': 'Nombre de visiteurs maximum',
  'squealRemarksComponent.remarkInput.peakOccurredOn': 'Le maximum a eu lieu le',
  'squealRemarksComponent.remarkInput.remarkRequired': 'Veuillez fournir une remarque',
  'squealRemarksComponent.remarkInput.remark': 'Remarque...',
  'squealTrendComponent.selectSqueal':
    "S'il vous plaît sélectionner une entrée de heatmap pour voir la tendance de squeal",
  'consumptionExport.reportSent': 'Rapport envoyé',
  'consumptionExport.reportFailed': `Le rapport n'a pas pu être généré`,
  'dashboard.error.title': 'Ce tableau de bord ne fonctionne pas',
  'dashboard.error.subTitle': `Nous ne sommes pas en mesure d'afficher ce tableau de bord en raison d'une erreur.`,

  'widget.error.title': 'Erreur',
  'widget.error.subTitle': `Impossible d'afficher le widget`,

  //AgEventLog Widget
  'widget.agEventLog.header.beginTime': 'Début',
  'widget.agEventLog.header.endTime': 'Arrêt',
  'widget.agEventLog.header.name': 'Nom',
  'widget.agEventLog.header.tag': 'Marque',
  'widget.agEventLog.header.lastValue': 'Dernière valeur',
  'widget.agEventLog.header.inUse': 'Utilisé',
  'widget.agEventLog.header.error': 'Erreur',
  'widget.agEventLog.header.alarm': 'Alarme',
  'widget.agEventLog.header.duration': 'Durée',
  'widget.agEventLog.currentState': 'Valeur actuelle',

  // Settings components
  'entityTypeManager.entityTypeConfig.name': 'Nom',
  'entityTypeManager.entityTypeConfig.marker': 'Marker',
  'entityTypeManager.entityTypeConfig.tag': 'Tag',
  'entityTypeManager.entityTypeConfig.field': 'Field',
  'entityTypeManager.entityTypeConfig.confirm': 'Confirmer',
  'entityTypeManager.entityTypeConfig.saveChanges': 'Enregistrer',
  'entityTypeManager.entityTypeConfig.discardChanges': 'Annuler',
  'entityTypeManager.entityTypeConfig.translations': 'Traductions (facultatif)',
  'entityTypeManager.entityTypeConfig.unsavedChanges': 'Il y a des modifications non enregistrées',
  'entityTypeManager.entityTypeConfig.unsavedChangesVerbose':
    'Est-ce que vous voulez annuler ou enregistrer les modifications?',
  'entityTypeManager.entityTypeConfig.englishRequired': 'Veuillez remplir le champ du nom Anglais',
  'entityTypeManager.entityTypeConfig.required': 'Requis',
  'entityTypeManager.entityTypeConfig.tagName': 'Nom de tag',
  'entityTypeManager.entityTypeConfig.tagType': 'Type de tag',
  'entityTypeManager.entityTypeConfig.typeRequired': 'Veuillez sélectionner le type du tag',
  'entityTypeManager.entityTypeConfig.nameRequired': 'Veuillez remplir le champ du nom du tag',
  'entityTypeManager.entityTypeConfig.discardedChanges': 'Modifications ont été annulées',
  'entityTypeManager.entityTypeConfig.somethingWentWrong': 'Quelque chose a mal tourné',
  'entityTypeManager.entityTypeConfig.invalidPreset':
    "Entité preset n'est pas valide, impossible d'enregistrer",
  'entityTypeManager.entityTypeConfig.createEntity': "Création d'une entité",
  'entityTypeManager.entityTypeConfig.updateEntity': "Modification d'une entité",
  'entityTypeManager.entityTypeConfig.confirmDeleteEntity': 'Confirme effacement',
  'entityTypeManager.entityTypeConfig.createdEntity': 'Entité créée avec succès',
  'entityTypeManager.entityTypeConfig.updatedEntity': 'Entité modifiée avec succès',
  'entityTypeManager.entityTypeConfig.deletedEntity': 'Entité supprimée avec succès',
  'entityTypeManager.entityTypeConfig.entityType': "Type d' entité",
  'entityTypeManager.entityTypeConfig.entityTypeSite': 'Site',
  'entityTypeManager.entityTypeConfig.entityTypeBuilding': 'Bâtiment',
  'entityTypeManager.entityTypeConfig.entityTypeFloor': 'Étage',
  'entityTypeManager.entityTypeConfig.entityTypeSpace': 'Area',
  'entityTypeManager.entityTypeConfig.entityTypeRoom': 'Espace',
  'entityTypeManager.entityTypeConfig.entityTypeEquipment': 'Apprareil',
  'entityTypeManager.entityTypeConfig.entityTypeDatapoint': 'Point de données',
  'entityTypeManager.entityTypeConfig.deleteTheFollowing':
    'Voulez-vous vraiment supprimer ce qui suit?',
  'entityTypeManager.entityTypeConfig.ofType': 'de type',
  'entityTypeManager.entityTypeConfig.unnamed': 'Nouveau point de données',
  'entityTypeManager.entityTypeConfig.titleDatapointConfigurator':
    'Configuration des points de données',
  'entityTypeManager.entityTypeConfig.datapointName': 'nom du point de données',
  'entityTypeManager.tagsForm.noWhitespaces': 'Aucun espace autorisé',
  'entityTypeManager.tagsForm.reservedKeyword': 'Mot-clé réservé',
  'entityTypeManager.tagsForm.noDuplicateTags': 'Aucun tag en double autorisé',

  'datapointsOverview.exportCSV': 'Télécharger les points de données dans un fichier csv',
  'datapointsOverview.manageEntities': 'Gérer des entités',
  'datapointsOverview.reload': 'Actualiser les points de données',
  'datapointsOverview.editDatapoint': 'Modifier le point de données',
  'datapointsOverview.deleteDatapoint': 'Supprimer le point de données',
  'datapointsOverview.clearFilters': 'Supprimer les filtres',

  'entityConfigurator.entityForm.entityName': 'Nom',
  'entityConfigurator.entityForm.nameRequired': 'Veuillez remplir le champ du nom',
  'entityConfigurator.entityForm.entityPreset': 'Entité preset',
  'entityConfigurator.entityForm.presetRequired': 'Veuillez sélectionner un preset',
  'entityConfigurator.entityForm.tagValueRequired': 'Veuillez remplir le champ du valeur du tag',
  'entityConfigurator.entityForm.tagValue': 'Valeur du tag',
  'entityConfigurator.entityForm.updatedEntity': 'a été modifié avec succès',
  'entityConfigurator.entityForm.createdEntity': 'a été créé avec succès',
  'entityConfigurator.entityForm.unitRequired': "Veuillez sélectionner l'unité du point de donnée",
  'entityConfigurator.entityForm.unit': 'Unité',

  // User Overview
  'userOverview.admin': 'Administrateur',
  'userOverview.noData': 'Pas de données',

  // Role Overview
  roleOverview: 'Aperçu des rôles',
  'roleOverview.role': 'Rôle',
  'roleOverview.permissions': 'Permissions',
  'roleOverview.providesAccess': 'Donne accès à',
  'roleOverview.and': 'et',
  'roleOverview.noPermissions': 'Pas de permissions',
  'roleOverview.view': 'Voir',
  'roleOverview.edit': 'Modifier',
  'roleOverview.invalid': 'Permission invalide',
  'roleOverview.canNotDeleteAdminRole': 'Rôle admin peut pas être supprimé',

  // Role Modal
  'roleModal.addPermission': 'Ajouter permission',
  'roleModal.accessibleDatapoints': 'Points de données pour',
  'roleModal.roleName': 'Nom du rôle',
  'roleModal.createdRole': 'a été créé avec succès',
  'roleModal.updatedRole': 'a été modifié avec succès',
  'roleModal.roleNameRequired': 'Veuillez remplir le champ du nom du rôle',
  'roleModal.editRole': 'Modifier le rôle',
  'roleModal.newRole': 'Nouveau rôle',

  // Customer overview
  'customerOverview.create': 'Nouveau client',

  // Permissions Modal
  'permissionsModal.step1': 'Étape 1',
  'permissionsModal.step1description': "Accorder l'accès à...",
  'permissionsModal.step1ToolTip': `La sélection d'une entité dans l'arborescence ci-dessous, donnera accès à cette entité et à tous ses enfants. Les tags disponibles à l'étape 2 seront mises à jour lors de la sélection d'une entité.`,
  'permissionsModal.step2': 'Étape 2',
  'permissionsModal.step2description': 'Où il y a des tags et markers...',
  'permissionsModal.step2ToolTip': `La sélection de tags dans la liste ci-dessous limitera cette permission en donnant accès aux entités qui ont toutes les tags sélectionnées. La sélection de plus de tags se traduira donc par un plus petit ensemble de points de données auxquels la permission donnera accès, ce qui se reflète à l'étape 3.`,
  'permissionsModal.step3': 'Étape 3',
  'permissionsModal.step3description': 'Aperçu des points de données',
  'permissionsModal.step3ToolTip': `La sélection d'une entité et de tags se traduira par un ensemble de points de données auxquels cette permission donnera accès. Ces points de données peuvent être prévisualisés ci-dessous.`,
  'permissionsModal.searchTags': 'Chercher des tags...',
  'permissionsModal.availableTags': 'Tags disponibles pour',
  'permissionsModal.selectedForPermission': 'Tags sélectionnées pour permission',
  'permissionsModal.updatingPermission': 'Mise à jour la permission',
  'permissionsModal.creatingPermission': 'Créer la permission',
  'permissionsModal.updateFailed': 'La mise à jour de la permission a échoué',
  'permissionsModal.creationFailed': 'La création de la permission a échoué',
  'permissionsModal.updateSuccess': 'La permission a été mis à jour avec succès',
  'permissionsModal.creationSuccess': 'La permission a été créé avec succès',
  'permissionsModal.editPermission': 'Modifier la permission',
  'permissionsModal.newPermission': 'Nouvelle permisison',
  'permissionsModal.for': 'pour',
  'permissionsModal.permission': 'Permission',
  'permissionsModal.companyEntities': `Entités de l'organisation`,
  'permissionsModal.externalEntities': 'Entités exterieurs',

  // terms of use
  'title.terms-of-use': "conditions d'utilisation",

  //Support en Contact Modal
  'support.title': 'Support',
  'contact.title': 'contacter',
  'support.succes.message': 'Message has been succesfully sent',
  'support.error.message': "Erreur lors de l'envoi d'un message",
  'label.name': 'Nom',
  'label.surName': 'Nom de famille',
  'label.email': 'Email',
  'label.phone': 'Numéro de téléphone',
  'label.title': 'Titre',
  'label.description': 'La description',
  'submit.form': 'Soumettre',
  'cancel.form': 'Annuler',
  'support.headertext':
    'Veuillez remplir le formulaire ci-dessous et nous vous contacterons dès que possible au sujet de votre demande de support.',
  'contact.headertext':
    'Veuillez remplir le formulaire ci-dessous et nous vous contacterons dès que possible au sujet de votre demande.',

  // Overview pagenames
  'customers.overviewPage.name': 'Aperçu clients',
  'datapoints.overviewPage.name': 'Aperçu points de données',
  'users.overviewPage.name': 'Aperçu utilisateurs',

  // Tag Container
  'tagContainer.noMoreTagsFor': `Il n'y a plus de tags disponibles pour`,
  'tagContainer.noTagsPleaseSelect':
    'Veuillez sélectionner un entité pour afficher les tags disponibles',

  // Dashboard modal
  'menu.dashboardForm.dashboardName': 'Nom du dashboard',
  'menu.dashboardForm.nameRequired': 'Veuillez remplir le champ du nom du dashboard',
  'menu.dashboardForm.iconRequired': 'Veuillez sélectionner une icône',
  'menu.dashboardForm.positionDashboard': 'Position du dashboard',
  'menu.dashboardForm.positionHelp':
    '(Facultatif) Sélectionnez un dashboard à droite pour faire de ce nouveau dashboard un dashboard enfant (sous-catégorie) du dashboard sélectionné. Laissez vide pour créer un dashboard sans relation hiérarchique avec les autres dashboards.',
  'menu.dashboardForm.positionPlaceholder': '(Facultatif) Sélectionnez un dashboard parent ',
  'menu.dashboardForm.favouriteDashboard': 'Ajouter aux favoris',
  'menu.dashboardForm.icon': 'Icône du dashboard',
  'menu.dashboardForm.creationFailed': 'La création du dashboard a échoué',
  'menu.dashboardForm.creationSuccess': 'Le dashboard a été créé avec succès',
  'menu.dashboardForm.emptyTitle': 'Un titre doit comprendre au moins un caractère',
  'menu.dashboardForm.updateFailed': 'La mise à jour de la dashboard a échoué',
  'menu.dashboardForm.updateSuccess': 'Le dashboard a été mis à jour avec succès',
  'menu.dashboardForm.removeFailed': 'La suppression du dashboard a échoué',
  'menu.dashboardForm.removeSuccess': 'La suppression du dashboard réussie',
  'menu.dashboardForm.dragFailed': 'La mise à jour de la dashboards a échoué',
  'menu.dashboardForm.dragSuccess': 'Le dashboards a été mis à jour avec succès',
  'menu.dashboardTree.createTopLevelItem': 'Créer un dashboard de niveau supérieur',
  'menu.settings': 'Réglages',
  'menu.dashboardCreate': 'Créer un tableau de bord',
  'menu.dataEntryCreate': 'Créer un tableau de bord de saisie de données',

  // Confidential information modal
  'title.confidential.information': 'Informations confidentielles',

  // compliance Modal
  'title.compliance': 'Conformité',

  // Units Overview
  unitsOverview: 'Aperçu des unités',
  'unitEdit.editUnit': `Modifier l'unité`,
  'unitEdit.updateFailed': `La mise a jour de l'unité a échoué`,
  'unitEdit.updateSuccess': `L'unité a été mise a jour avec succès`,
  'unitCreate.newUnit': 'Nouvelle unité',
  'unitCreate.creationFailed': `La création de l'unité a échoué`,
  'unitCreate.creationSuccess': `L'unité a été créé avec succès`,
  'unitForm.unitName': `Nom de l'unité`,
  'unitForm.nameRequired': `Veuillez remplir le champ du nom de l'unité`,
  'unitForm.unitFormat': `Format de l'unité`,
  'unitForm.unitType': 'Type',

  // Data table
  'dataTable.reload': 'Actualiser',
  'dataTable.manage': 'Gérer',
  'dataTable.exportCSV': 'Télécharger dans un fichier csv',
  'dataTable.edit': 'Modifier',
  'dataTable.delete': 'Supprimer',
  'dataTable.close': 'Fermer',
  'dataTable.cancel': 'Annuler',
  'dataTable.save': 'Sauver',
  'dataTable.saveAndClose': 'Sauver et fermer',
  'dataTable.new': 'Ajouter un nouveau',
  'dataTable.clearFilters': 'Supprimer les filtres',
  'dataTable.lastValue': 'Dernière valeur',
  'datapointsOverview.datapointSingle': 'Point de données',
  'datapointsOverview.datapointPlural': 'Points de données',
  'usersOverview.userSingle': 'Utilisateur',
  'usersOverview.userPlural': 'Utilisateurs',

  // Layout components
  'settings.themeSettings': 'Paramètres du Thème',
  'settings.collapsedMenu': 'Menu réduit',
  'settings.menuShadow': 'Ombre de menu',
  'settings.lightTheme': 'Mode lumière',
  'settings.borderlessCards': 'Cartes sans bordures',
  'settings.squaredBorders': 'Bordures carrées',
  'settings.fixedWidth': 'Largeur fixe',
  'topBar.profileMenu.hello': 'Bonjour {name}',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.theme': 'Thème',
  'topBar.profileMenu.editProfile': 'Editer profile',
  'topBar.profileMenu.logout': 'Se déconnecter',
  'topBar.profileMenu.preferences': 'Préférences',
  'topBar.profileMenu.advanced': 'Avancé',
  'menu.favourites': 'Favoris',
  'menu.dashboard': 'Tableau',
  'menu.dashboards': 'Tableaux',
  'menu.dataEntry': 'Saisie des données',
  'menu.dashboards.edit': 'Gérer les tableaux',
  'menu.dashboard.shareDashboard': 'Partager des tableaux',
  'menu.dashboard.share': 'Partager',
  'menu.dashboard.shareFailed': "Le partage du tableau n'a pas réussi",
  'menu.dashboard.shareSuccess': 'Le tableau a été partagés avec succès',
  'menu.dashboard.newDashboard': 'Gérer dashboards',
  'menuBar.logoDropdown.currentCustomer': 'Client actuel',
  'layout.globalTimeSelection': `Réglage de l'heure globale`,
  'layout.sectionTimeSelection': `Réglage de l'heure section`,

  // Share Dashboard
  'shareDashboard.selectDashboards': 'Sélection des tableaux',
  'shareDashboard.shareWith': 'Partager avec',
  'shareDashboard.permissionLevel': 'Niveau des permissions',
  'shareDashboard.allowEdit': 'Collection de dashboards maître',
  'shareDashboard.affectedUsers': 'Abonnés',
  'shareDashboard.usersByMailAddress': 'Utilisatuers par adresse mail',
  'shareDashboard.knownUsers': 'Untilisateurs connus',

  // Delete modal
  'modal.delete.success': 'Supprimé avec succès',
  'modal.delete.error': 'Quelque chose a mal tourné',

  // Invite page
  'invite.welcomeToAviary': 'Bienvenue sur Canary',

  // General
  'general.unsavedChanges': 'Il y a des modifications non enregistrées',
  'general.savedChanges': 'Modifications ont été enregistrées',
  'general.noChangesToSave': 'Aucun changement pour sauver',
  'general.save': 'Enregistrer',
  'general.cancel': 'Annuler',
  'general.yes': 'Oui',
  'general.no': 'Non',
  'general.someValuesAreToHeigh': 'Certaines valeurs sont trop élevées',
  'general.incorrectPassword': 'Mot de passe incorrect',
  'general.inputOwnPassword': 'Veuillez saisir votre propre mot de passe',

  // Languages
  'language.dutch': 'Neérlandais',
  'language.english': 'Anglais',
  'language.french': 'Français',
  'language.nl-NL': 'Neérlandais',
  'language.en-US': 'Anglais',
  'language.fr-FR': 'Français',

  // Report
  'report.create': 'Crée rapport',
  'report.site': 'Location',
  'report.address': 'Adresse',
  'report.contact': 'Contact',
  'report.creationDate': 'Généré le',
  'report.scraper': 'Scraper',
  'report.phone': 'Tél.',
  'report.openingTimes': "Heures d'ouverture",
  'report.visitorsInformation': 'Info visiteurs',
  'report.entered': 'ENTREES',
  'report.exited': 'SORTIS',
  'report.max': 'MAX',
  'report.heatmap': 'Heatmap',
  'report.squeals': 'Squeals',
  'report.noSqueals': 'Pas de Squeals',
  'report.none': 'Non',
  'report.acknowledged': 'Confirmé le',
  'report.notAcknowledged': 'Pas confirmé',
  'report.remark': 'Remarque Squeal',
  'report.footer': 'Ce rapport a été créé par Canary® - un produit de Canary® by pour',
  'report.waitForLoading': 'Veuillez patienter - création du rapport en cours',

  // Error handling
  'global.message.somethingWentWrong': 'Quelque chose a mal tourné',

  // Calendar months
  'month.1': 'Janvier',
  'month.2': 'Février',
  'month.3': 'Mars',
  'month.4': 'Avril',
  'month.5': 'Mai',
  'month.6': 'Juin',
  'month.7': 'Juillet',
  'month.8': 'Août',
  'month.9': 'Septembre',
  'month.10': 'Octobre',
  'month.11': 'Novembre',
  'month.12': 'Décembre',

  // General Component Captions
  'componentCaptions.noMarkers': 'Pas de marqueurs',
  'componentCaptions.noTags': 'Pas de balises',
  'componentCaptions.noDatapoints': 'Pas de points de données',
  'componentCaptions.noRules': 'Pas de règles',
  'componentCaptions.noOrganisations': 'Pas de organisation',
  'componentCaptions.noResults': 'Pas de résultat',
  'componentCaptions.searchToSelect': 'Chercher',
  'componentCaptions.noConditions': 'Pas de conditions',
  'componentCaptions.addCondition': 'Ajouter condition',

  'organisation.structure.type.EQUITY': 'Equity',
  'organisation.structure.type.FRANCHISE': 'Franchise',
  'organisation.structure.type.INVESTMENT': 'Investment',
  'organisation.structure.type.LEASING': 'Leasing',

  'organisation.env.bulkUpdateWarning':
    'Vous êtes sur le point de mettre à jour tous les éléments de ce groupe.',

  'organisation.env.type.ASSETS': 'Assets',
  'organisation.env.type.VEHICLES': 'Vehicles',
  'organisation.env.type.UPSTREAM': 'Upstream/Downstream Activities',

  'organisation.environments.type.CORE': 'Core',
  'organisation.environments.type.FRANCHISE': 'Franchise',
  'organisation.environments.type.LEASING': 'Leasing',

  'reportingYear.status.INCOMPLETE': 'Incomplète',
  'reportingYear.status.COMPLETE': 'Complète',
  'reportingYear.status.IN_PROGRESS': 'En cours',

  'reportingYear.datepicker.info':
    "Aucune saisie de données n'est autorisée pour les périodes de rapport qui sont verrouillées.",

  'search.goto.placeholder': 'Rechercher des pages, des fichiers fixes, des tableaux de bord ...',
  'search.switchCustomer.placeholder': 'Recherche du client vers lequel vous souhaitez basculer',
  'search.noResultFound': 'Aucun résultat trouvé',

  'shortcut.GOTO': 'Aller à',
  'shortcut.SWITCH_CUSTOMER': 'Switch Client',
  'shortcut.VERSIONS': 'Versions',
  'shortcut.CHEATSHEET': 'Feuille de triche',
  'shortcut.CLOSE': 'Fermer',

  'file.type.images': 'Images',
  'file.type.documents': 'Documents',

  'energyContracts.warning.missingContractsItems': '{count} objets',
  'energyContracts.warning.missingContracts': 'manquent un contrat',
  'energyContracts.warning.missingContractItem': 'Un article',
  'energyContracts.warning.missingContract': 'manque un contrat',
}
