export default {
  'form.submit': 'Save',
  'form.reset': 'Reset',
  'form.field.error.required': 'This field is required',
  'form.tag.error.required': 'A value for this tag is required',
  'form.validation.unique': 'This value is not unique',
  'form.validation.containsErrors': 'Resolve the errors before saving',

  // Specific
  'form.email.alreadyExists': ' is already being used by someone.',
  'form.email.alreadyInvited': ' has already been invited.',

  // Labels
  'form.label.name': 'Name',
  'form.label.email': 'Email',
  'form.label.emails': 'Email address(es)',
  'form.label.phone': 'Phone',
  'form.label.surname': 'Surname',
  'form.label.firstname': 'First name',
  'form.label.roles': 'Roles',
  'form.label.fixedPermissions': 'Fixed Permissions',
  'form.label.optional': '(Optional)',
  'form.label.street': 'Street',
  'form.label.streetNumber': 'Number',
  'form.label.postalCode': 'ZIP code',
  'form.label.city': 'City',
  'form.label.country': 'Country',
  'form.label.vatNumber': 'VAT',
  'form.label.website': 'Website',
  'form.label.address': 'Address',
  'form.label.administrator': 'Admin',
  'form.label.createdAt': 'Created',
  'form.label.updatedAt': 'Last update',
  'form.label.hasDatapoints': 'Database',
  'form.label.isActive': 'Active',
  'form.label.deactivated': 'Deactivated',
  'form.label.isDealer': 'Is dealer',
  'form.label.dealer': 'Dealer',
  'form.label.logo': 'Logo',
  'form.label.noImage': 'No image',
  'form.label.yes': 'Yes',
  'form.label.no': 'No',
  'form.label.adminFirstName': 'Admin first name',
  'form.label.adminLastName': 'Admin last name',
  'form.label.adminMail': 'Admin mail',
  'form.label.mobile': 'Mobile phone',
  'form.label.avatar': 'Profile picture',
  'form.label.customers': 'Customers',
  'form.label.dataLimit': 'Data limit',
  'form.label.smsLimit': 'SMS Limit',
  'form.label.energyClassification': 'Energy Classification',
  'form.label.reportingYear': 'Reporting Year',
  'form.label.legalEntity': 'Legal Entity',
  'form.label.customerCode': 'Customer Code',
  'form.label.internalId': 'Internal Ref.',
  'form.label.customerMail': 'Company mail',
  'form.label.numericOnly': 'Numeric characters only',
  'form.label.noZeroesAfterPlus': '[+][country code][area code][local phone number]',

  // Generic
  'form.input.required': ' is a required field.',
  'form.input.invalid': ' is an invalid value.',
  'form.select.required': 'You must select a value.',
  'form.select.requiredOne': 'You must select at least one value.',
  'form.password.minimum': 'Minimum password length: 8 characters',
  'form.password.maximum': 'Maximum password length: 50 characters',
  'form.password.regex': 'Password requires at least 1 uppercase letter and 1 digit',
  'form.password.match': 'Passwords do not match',
  'form.dropHereToUpload': 'Drop here to upload',
  'form.uploading': 'Uploading...',
  'form.dropAfileToReplaceImage': 'Drop a file to replace image',
  'form.moreInfo': 'More info',
  'form.acceptCookies': 'Accept cookies',
  'form.iAgree': 'I agree',

  // Validation
  'form.validation.maxLength': 'The maximum length for this field is ',
  'form.validation.characters': 'characters.',
  'form.validation.noUserWithThisEmailAddress': 'There is no user with this email address.',

  'form.field.education.primary_education': 'PRIMARY EDUCATION',
  'form.field.education.basic_education': 'BASIC EDUCATION',
  'form.field.education.secondary_education': 'SECONDARY EDUCATION',
  'form.field.education.college_graduate': 'COLLEGE GRADUATE',
  'form.field.education.bachelor': 'BACHELOR',
  'form.field.education.master': 'MASTER',
  'form.field.education.doctor': 'DOCTOR',

  // Form fields from DB
  'Postal Code': 'Postal Code',
  City: 'City',
  Country: 'Country',
  Street: 'Street',
  'Street number': 'Street number',
  'VAT (Format: Country code and only numbers eg. BE0123456789)':
    'VAT (Format: Country code and only numbers eg. BE0123456789)',
  Name: 'Name',
  'Internal Ref': 'Internal Ref',
  Remarks: 'Remarks',
  'Is Deactivated': 'Is Deactivated',
}
