import { transparentize } from 'polished'
import styled from 'styled-components'

import Field from 'components/Form/components/Field'
import { Label, Value } from 'components/Form/components/Field/styled'

export const UserSelectFieldStyled = styled(Field)`
  padding: 0.5rem 0;

  ${Label} {
    margin: 0 0 auto;
  }

  ${Value} {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start !important;

    > .Tags {
      width: 100%;
      .Tooltip {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .ValuePickerValue {
      display: flex;
      flex-wrap: wrap;
    }
  }
`

export const CustomValueCreateStyled = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    display: none;
  }

  .CustomValueCreate__value {
    background: ${({ theme }) => transparentize(0.9, theme.color.main)};
    color: ${({ theme }) => theme.color.mainText};
    border-radius: 0.2rem;
    margin: 0.5rem auto 0.5rem 1rem;
    font-weight: 600;

    &:not(:empty) {
      padding: 0.2rem 0.5rem;
    }
  }
`
