import { setContext } from '@apollo/client/link/context'

/**
 * Putting the company id in the headers so there is no need to pass it to every request.
 */
const companyContext = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const customerData = localStorage.getItem('customer')
  // return the headers to the context so httpLink can read them
  if (!customerData) {
    return { headers }
  }

  const customer = JSON.parse(customerData)

  if (!customer?.id) {
    return { headers }
  }

  return {
    headers: {
      ...headers,
      company: customer?.id,
    },
  }
})

export default companyContext
