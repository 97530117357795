export default {
  'sustainability.menu': 'Duurzaamheid',
  'sustainability.noAccess': 'Geen toegang tot duurzaamheid',

  'sustainability.menu.audit': 'Audit',
  'sustainability.menu.audit.sdg': 'SDG Audit',
  'sustainability.menu.audit.dataReadiness': 'Data Readiness',

  'sustainability.menu.modules': 'Modules',
  'sustainability.menu.modules.csrd': 'CSRD (Corporate Sustainability Reporting Directive)',
  'sustainability.menu.modules.esg': 'ESG (Environment Social Governance)',
  'sustainability.menu.modules.ghg': 'GHG (Greenhouse Gas Protocol)',
  'sustainability.menu.modules.gri': 'GRI (Global Reporting Initiative)',
  'sustainability.menu.modules.sbti': 'SBTi (Science Based Targets initiative)',
  'sustainability.menu.modules.sdg': 'SDG (Sustainable Development Goals)',

  'sustainability.module.gri.title': 'GRI',
  'sustainability.module.gri.report.title': 'Rapport genereren',
  'sustainability.module.gri.report.range': 'Bereik',
  'sustainability.module.gri.report.resolution': 'Resolutie',
  'sustainability.module.gri.report.trends': 'Trends',
  'sustainability.module.gri.report.benchmark': 'Benchmark',
  'sustainability.module.gri.report.startValue': 'Start waarde',
  'sustainability.module.gri.report.transparencyDetail': 'Transparante details',
  'sustainability.module.gri.report.conversionFactors': 'Omrekeningsfactoren',
  'sustainability.module.gri.report.button.pdf': 'Genereer PDF',
  'sustainability.module.gri.report.button.csv': 'Genereer CSV',

  'sustainability.menu.crsd  ': 'CSRD',
  'sustainability.menu.csrd.research': 'Research',
  'sustainability.menu.csrd.doubleMateriality': 'Double Materiality',
  'sustainability.menu.csrd.configurationAssistant': 'Configuration Assistant',
  'sustainability.menu.csrd.dataSurvey': 'Data Survey',
  'sustainability.menu.csrd.report': 'Report',
  'sustainability.menu.csrd.validation': 'Validation',

  'sustainability.module.gri.bulk.enable.success': 'Selected items are enabled',
  'sustainability.module.gri.bulk.enable.error': 'An error occured while enabling the disclosures',

  'sustainability.module.gri.bulk.disable.success': 'Selected items are disabled',
  'sustainability.module.gri.bulk.disable.error':
    'An error occured while disabling the disclosures',

  'sustainability.module.gri.disclsoureDisableModel.title': 'Disable disclosure(s)',
  'sustainability.module.gri.disclsoureDisableModel.button.submit': 'Disable disclosure(s)',

  'sustainability.module.ghg.title': 'GHG (Greenhouse Gas Protocol)',

  'sustainability.ghg.scope3.all': 'All',
  'sustainability.ghg.scope3.upstreamPurchasedEnergyFuels': 'Upstream - Purchased Energy Fuels',
  'sustainability.ghg.scope3.upstreamPurchasedVehicleFuels': 'Upstream - Purchased Vehicle Fuels',
  'sustainability.ghg.scope3.upstreamPurchasedEnergy': 'Upstream - Purchased Energy',
  'sustainability.ghg.scope3.tndLosses': 'T&D losses',
}
