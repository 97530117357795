import { setContext } from '@apollo/client/link/context'

/**
 * Inserts the baerer token into the requests
 */
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  if (!token) {
    return { headers }
  }
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }
})

export default authLink
