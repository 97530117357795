import React from 'react'

import { useLazyQuery } from '@apollo/client'

import { ACCOUNT_BY_EMAIL_QUERY } from '../../../../services/user'
import Button from '../../../Global/Button'
import { CustomValueCreateStyled } from './styled'

const CustomValueCreate = ({ value, onCreate }) => {
  const [getAccountDetails] = useLazyQuery(ACCOUNT_BY_EMAIL_QUERY, {
    onCompleted: (data) => onCreate(data.accountByEmail),
  })
  const handleCustomValueCreate = async () => {
    getAccountDetails({ variables: { email: value } })
  }
  return (
    <CustomValueCreateStyled className="CustomValueCreate">
      <span className="CustomValueCreate__value">{value}</span>
      <Button variant="smallButton" label="Add" isPrimary onClick={handleCustomValueCreate} />
    </CustomValueCreateStyled>
  )
}

export default CustomValueCreate
