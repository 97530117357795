import React, { useState } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import Skeleton from 'components/Form/components/Skeleton/styled'
import Table from 'components/Global/Table'
import DetailPortal from 'components/Layout/DetailPortal'
import { useDetailPortal } from 'components/Layout/DetailPortal/hooks'

import { EditableTableFieldStyled } from './styled'

const EditableTableDetailField = ({
  value,
  name,
  filter,
  dependencies,
  columns = [{}],
  defaultRowValue,
  onChange,
  onRowActionClick,
  detailComponent,
  detailComponentProps,
  detailDataKey,
  disableDelete,
  disableEdit,
  disabled,
  refetchParent,
  normalizeData,
  portalElement = 'list-detail',
  ...fieldProps
}) => {
  const { detailId, setDetail, createMode } = useDetailPortal(detailDataKey)
  const [rowAction, setRowAction] = useState()

  return (
    <>
      <EditableTableFieldStyled {...fieldProps} disabled={disabled}>
        {({ watch, loading, setValue, isEditMode }) => {
          if (loading) {
            return <Skeleton width="200px" />
          }
          const currentArray = watch(name)

          const filterFn = ({ item }) => {
            if (!filter) {
              return true
            }
            return filter(item, { watch })
          }

          const handleArrayUpdate = ({ name: actionName, rowIndex, ...payload }) => {
            if (disabled) {
              return
            }
            switch (actionName) {
              case 'edit':
                setRowAction({ rowIndex, name: actionName })
                return setDetail(payload)
              case 'add':
                setRowAction({ rowIndex, name: actionName })
                return setDetail({ createMode: true })
              case 'delete':
                const newValue = [
                  ...currentArray.slice(0, rowIndex),
                  ...currentArray.slice(rowIndex + 1),
                ]
                onChange && onChange(newValue)
                return setValue(name, newValue)
              default:
            }
            onRowActionClick && onRowActionClick({ ...payload, dataKey: name })
          }

          const handleDetailAction = ({ name: actionName, ...payload }) => {
            switch (actionName) {
              case 'save':
                const data = normalizeData ? normalizeData(payload.data) : payload.data

                if (data !== undefined) {
                  const newValue =
                    rowAction.name === 'edit'
                      ? [
                          ...currentArray.slice(0, rowAction.rowIndex),
                          data,
                          ...currentArray.slice(rowAction.rowIndex + 1),
                        ]
                      : [data, ...currentArray]

                  onChange && onChange(newValue)

                  setValue(
                    name,
                    payload.orderByField ? _.sortBy(newValue, payload.orderByField) : newValue,
                    { shouldDirty: true }
                  )
                }

                setDetail(null)
                break

              case 'cancel':
              default:
                setDetail(null)
                break
            }
          }
          const tableProps = {
            columns,
            defaultRowValue,
            readOnly: true,
            buttons: isEditMode && [
              ...(disableEdit ? [] : [{ name: 'edit', icon: 'lnr lnr-pencil' }]),
              ...(disableDelete ? [] : [{ name: 'delete', icon: 'lnr lnr-trash' }]),
            ],
            onArrayUpdate: handleArrayUpdate,
          }

          return name ? (
            <>
              <Controller
                name={name}
                render={({ onChange: fromFieldOnChange }) => {
                  const handleChange = (value) => {
                    if (name) {
                      fromFieldOnChange(value)
                    }
                    onChange && onChange(value)
                  }
                  const renderValue =
                    value === undefined && name === undefined ? value : currentArray

                  const list = renderValue?.filter(filterFn)

                  return (
                    <Table
                      {...tableProps}
                      value={list}
                      onChange={handleChange}
                      onDelete={fieldProps.onDelete}
                      fixedFileId={detailComponentProps.fixedFileId}
                    />
                  )
                }}
              />
              <DetailPortal
                element={portalElement}
                id={detailId}
                isOpen={createMode || detailId}
                createMode={createMode}
                component={detailComponent}
                componentProps={detailComponentProps}
                onDetailAction={handleDetailAction}
              />
            </>
          ) : (
            <Table
              {...tableProps}
              onChange={onChange}
              value={value}
              onDelete={fieldProps.onDelete}
              fixedFileId={detailComponentProps.fixedFileId}
            />
          )
        }}
      </EditableTableFieldStyled>
    </>
  )
}

EditableTableDetailField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
}

export default EditableTableDetailField
