import { ApolloClient, from } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'

import cache from './cache'
import authContext from './context/authContext'
import companyContext from './context/companyContext'
import localeContext from './context/localeContext'

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, ...rest }) => {
  if (networkError?.statusCode === 401) {
    // unauthenticated
    window.location.href = '/user/login'
  }
})

const httpBatchLink = new BatchHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  batchInterval: 15, // ms
})

const client = new ApolloClient({
  link: from([errorLink, authContext, companyContext, localeContext, httpBatchLink]),
  cache,
})

export default client
