import React from 'react'

import { useAccountsByIds } from '../../../../services/user'
import { generateTagColor } from '../../../Dashboard/components/Tags/functions'
import TagsStyled from '../../../Dashboard/components/Tags/styled'
import Button from '../../../Global/Button'

const UserSelectValues = ({ value, unKnownValues }) => {
  const { data } = useAccountsByIds(unKnownValues)
  return (
    <TagsStyled className="Tags">
      <div className="ValuePickerValue">
        {[...value, ...(data || [])].map((user) => (
          <Button
            key={user.id}
            label={user.name}
            variant="tag"
            color={generateTagColor(user.name)}
            isActive
          />
        ))}
      </div>
    </TagsStyled>
  )
}

export default UserSelectValues
