import React, { createContext, useContext, useState } from 'react'

import { useQuery } from '@apollo/client'

import { ME_QUERY } from 'services/user'

export const WebsocketContext = createContext()
export const useSocket = () => {
  return useContext(WebsocketContext)
}

let registeredDatapoints = []

const WebsocketContextProvider = (props) => {
  const [incomingData, setIncomingData] = useState(null)
  const [squealCount, setSquealCount] = useState(null)
  const [squealCountPerSite, setSquealCountPerSite] = useState([])
  const customer = useQuery(ME_QUERY)
  const myId = customer.data ? customer.data.me.id : undefined

  const { socket } = props

  const inComingData = (data) => {
    setIncomingData(data)
  }

  const inComingSquealCount = (count) => {
    setSquealCount(count)
  }

  const inComingSquealCountPerSite = (countPerSite) => {
    setSquealCountPerSite(countPerSite)
  }

  socket.removeAllListeners('aviary_message')
  socket.on('aviary_message', inComingData)

  const subscribeToSquealCount = () => {
    socket.removeAllListeners('squeal_count')
    socket.emit('squeal_count', myId)
    socket.on('squeal_count', inComingSquealCount)
  }

  const subscribeToSquealCountPerSite = (sites) => {
    socket.removeAllListeners('squeal_count_per_site')
    socket.emit('squeal_count_per_site', { user: myId, sites })
    socket.on('squeal_count_per_site', inComingSquealCountPerSite)
  }

  const subscribe = (datapointList) => {
    let subscribeList = []

    datapointList.forEach((uuid) => {
      if (!registeredDatapoints.includes(uuid)) {
        registeredDatapoints.push(uuid)
        subscribeList.push(uuid)
      }
    })
    if (subscribeList.length) {
      socket.emit(
        'aviary_message',
        JSON.stringify({ type: 'subscribe', datapoints: subscribeList })
      )
    }
  }
  const unsubscribe = () => {
    socket.emit('aviary_message', JSON.stringify({ type: 'unsubscribe' }))
    registeredDatapoints = []
  }

  return (
    <WebsocketContext.Provider
      value={{
        subscribe,
        unsubscribe,
        subscribeToSquealCount,
        subscribeToSquealCountPerSite,
        incomingData,
        squealCount,
        squealCountPerSite,
      }}
    >
      {props.children}
    </WebsocketContext.Provider>
  )
}

export default WebsocketContextProvider
