import React, { useCallback, useEffect, useState } from 'react'

import { AnimatePresence } from 'framer-motion'
import { useDropzone } from 'react-dropzone'
import { injectIntl } from 'react-intl'

import imageStyleUrl, { imageStyles } from 'util/imageStyleUrl'
import useFileUpload from 'util/useFileUpload'
import { useCurrentCustomer } from 'services/store'

import Caption from 'components/Global/Caption'

import Button from '../../Button'
import Icon from '../../Icon'
import { DropzoneContainer, PresenceWrapper, PreviewImage } from './styled'

const isAbsoluteUrl = (url) => /^[a-z][a-z0-9+.-]*:/.test(url)

const Upload = ({
  value,
  onChange,
  onFileSelect,
  noClick = false,
  children,
  variant,
  reset,
  icon,
  placeholderIcon = 'fas fa-image',
  label,
  className = '',
  intl,
  hidePreview = false,
  hideLoading = false,
  imageStyle = imageStyles.small,
  loadingText,
  companyWide = false,
  deleteLabel = null,
  deleteFile,
}) => {
  const customer = useCurrentCustomer()

  const [setFile, { loading, error, memoryUrl, uploadedFile }] = useFileUpload(customer.id, {
    uploadOnSetFile: true,
    companyWide,
  })
  const [selectedFile, setSelectedFile] = useState(undefined)
  const [imgDeleted, setImgDeleted] = useState(false)
  useEffect(() => {
    if (uploadedFile && onChange) {
      onChange(uploadedFile.key, uploadedFile)
      setSelectedFile(uploadedFile)
    }
    // eslint-disable-next-line
  }, [uploadedFile])

  useEffect(() => {
    if (reset) setSelectedFile(undefined)
  }, [reset])

  useEffect(() => {
    if (memoryUrl && onFileSelect) {
      onFileSelect(memoryUrl)
    }
  }, [memoryUrl, onFileSelect])

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0])
    },
    [setFile]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick })

  const img = selectedFile
    ? imageStyleUrl(selectedFile.key, imageStyles[imageStyle])
    : isAbsoluteUrl(value)
    ? value
    : imageStyleUrl(value, imageStyles[imageStyle])

  const variants = {
    exit: { opacity: 0 },
    enter: { opacity: 1 },
  }

  const loadingTextValue =
    loadingText === undefined ? intl.formatMessage({ id: 'form.uploading' }) : loadingText

  return (
    <DropzoneContainer
      {...getRootProps()}
      className={`Upload ${className} ${isDragActive ? 'drag-active' : ''}`}
    >
      {typeof children === 'function' ? children({ open }) : children}
      {!hidePreview && img && !imgDeleted ? (
        <PreviewImage
          key={img}
          alt="preview"
          className="upload-preview"
          src={img}
          fallback="/resources/images/default-image.gif"
          preview={false}
        />
      ) : (
        placeholderIcon && <Icon icon={placeholderIcon} />
      )}
      <input {...getInputProps()} />
      {(label || icon) && (
        <Button
          onClick={() => {
            setImgDeleted(false)
            open()
          }}
          variant={variant}
          icon={icon}
          label={label}
          isActive={isDragActive}
        />
      )}
      {deleteLabel && (
        <Button
          onClick={() => {
            deleteFile()
            setImgDeleted(true)
          }}
          label={deleteLabel}
        />
      )}
      <AnimatePresence>
        {children && isDragActive && (
          <PresenceWrapper
            className="PresenceWrapper drop"
            initial="exit"
            animate="enter"
            exit="exit"
            variants={variants}
          >
            <Caption
              className="Caption"
              icon="fas fa-cloud-arrow-up"
              title={intl.formatMessage({ id: 'form.dropHereToUpload' })}
            />
          </PresenceWrapper>
        )}
        {error && (
          <PresenceWrapper
            className="PresenceWrapper error"
            initial="exit"
            animate="enter"
            exit="exit"
            variants={variants}
          >
            <Caption className="Caption" icon="fas fa-triangle-exclamation" title={error} />
          </PresenceWrapper>
        )}
        {selectedFile && !hidePreview && (
          <PresenceWrapper
            className="PresenceWrapper file"
            initial="exit"
            animate="enter"
            exit="exit"
            variants={variants}
          >
            <Caption className="Caption" icon="fas fa-file" title={selectedFile.name} />
          </PresenceWrapper>
        )}
        {loading && !hideLoading && (
          <PresenceWrapper
            className="PresenceWrapper uploading"
            initial="exit"
            animate="enter"
            exit="exit"
            variants={variants}
          >
            <Caption className="Caption" icon="fas fa-upload" title={loadingTextValue}>
              <span className="loader" />
            </Caption>
          </PresenceWrapper>
        )}
      </AnimatePresence>
    </DropzoneContainer>
  )
}

export default injectIntl(Upload)
