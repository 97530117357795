import React, { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { injectIntl } from 'react-intl'

import { useCompanyAccounts } from 'services/user'

import { generateTagColor } from 'components/Dashboard/components/Tags/functions'
import TagsStyled from 'components/Dashboard/components/Tags/styled'
import Skeleton from 'components/Form/components/Skeleton/styled'

import { FieldMessage } from '../../components/Field/styled'
import { UserSelectFieldStyled } from './styled'
import UserSelectValuePicker from './UserSelectValuePicker'
import UserSelectValues from './UserSelectValues'

const UserSelectField = ({
  value,
  name,
  filter,
  dependencies,
  userType,
  onChange,
  uncontrolled,
  readOnly,
  disableList = [],
  defaultValue,
  intl,
  ...fieldProps
}) => {
  const { data } = useCompanyAccounts(true)
  const [fieldErrors, setFieldErrors] = useState(null)

  const options = useMemo(() => {
    let users = []
    let res = []

    if (!readOnly && data) {
      switch (userType) {
        case 'voice_mobile':
          users = data.filter((user) => user.mobile !== null)

          res = users.map((user) => ({
            id: user.id,
            name: `${user.surname} ${user.firstname} (${user.mobile})`,
            type: 'voice_mobile',
            color: generateTagColor(`${user.surname} ${user.firstname} (${user.mobile})`),
          }))

          /* users = data.filter((user) => user.phone !== null)

          res = [
            ...res,
            ...users.map((user) => ({
              id: user.id,
              name: `${user.surname} ${user.firstname} (${user.phone})`,
              type: 'voice phone',
              color: generateTagColor(`${user.surname} ${user.firstname} (${user.phone})`),
            })),
          ] */
          break

        case 'voice_fixed':
          users = data.filter((user) => user.phone !== null)

          res = users.map((user) => ({
            id: user.id,
            name: `${user.surname} ${user.firstname} (${user.phone})`,
            type: 'voice_fixed',
            color: generateTagColor(`${user.surname} ${user.firstname} (${user.phone})`),
          }))
          break

        case 'sms':
          users = data.filter((user) => user.mobile !== null)
          res = users.map((user) => ({
            id: user.id,
            name: `${user.surname} ${user.firstname} (${user.mobile})`,
            type: userType,
            color: generateTagColor(`${user.surname} ${user.firstname} (${user.mobile})`),
          }))
          break

        default:
          // Default = 'mail'
          users = data.filter((user) => user.email !== null)
          res = users.map((user) => ({
            id: user.id,
            name: `${user.surname} ${user.firstname} (${user.email})`,
            type: userType,
            color: generateTagColor(`${user.surname} ${user.firstname} (${user.email})`),
          }))
      }
      return res.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    }
    return []
  }, [data, userType, readOnly])

  return (
    <UserSelectFieldStyled {...fieldProps}>
      {({ control, watch, loading, isEditMode }) => {
        if (loading) {
          return <Skeleton width="200px" />
        }
        if (isEditMode) {
          return (
            <Controller
              control={control}
              name={name}
              defaultValue={defaultValue}
              render={({ onChange: fromFieldOnChange, value: controllerValue = [] }) => {
                const handleChange = (value) => {
                  const update = value.map((v) => v.id)

                  if (!uncontrolled) {
                    fromFieldOnChange(update)
                  }
                  onChange && onChange(update)
                }
                const renderValue =
                  value === undefined && name === undefined ? value : controllerValue

                const { list, unknown } = renderValue.reduce(
                  ({ list, unknown }, item) => {
                    const itemId = item.id || item
                    const user = options.find((u) => u.id === itemId)
                    if (user) {
                      list.push(user)
                    } else {
                      unknown.push(itemId)
                    }
                    return { list, unknown }
                  },
                  { list: [], unknown: [] }
                )

                const handleRemoveItem = ({ id }) => {
                  const update = controllerValue.filter((item) => item !== id)
                  // const { [id]: removed, ...update } = controllerValue
                  if (!uncontrolled) {
                    fromFieldOnChange(update)
                  }
                  onChange && onChange(update)
                }

                const handleSearch = () => {
                  setFieldErrors(null)
                }

                const handleAddCustomValue = (customValue) => {
                  if (!customValue) {
                    setFieldErrors({
                      message: 'noUserWithThisEmailAddress',
                    })
                    return
                  }
                  const addedValue = {
                    ...customValue,
                    name: `${customValue.firstname} ${customValue.surname} (${customValue.email})`,
                  }
                  handleChange([addedValue, ...list])
                }

                return (
                  <TagsStyled className="Tags">
                    <UserSelectValuePicker
                      value={list}
                      unknownValues={unknown}
                      onAddCustomValue={handleAddCustomValue}
                      onChange={handleChange}
                      onSearch={handleSearch}
                      onRemoveItem={handleRemoveItem}
                      disabled={disableList.includes(name)}
                      options={options}
                    />
                    {fieldErrors && (
                      <FieldMessage>
                        {intl.formatMessage({ id: `form.validation.${fieldErrors.message}` })}
                      </FieldMessage>
                    )}
                  </TagsStyled>
                )
              }}
            />
          )
        }
        const fieldValue = value === undefined && name !== undefined ? watch(name) : value

        const { list, unknown } = fieldValue.reduce(
          ({ list, unknown }, item) => {
            const itemId = item.id || item
            const user = options.find((u) => u.id === itemId)
            if (user) {
              list.push(user)
            } else {
              unknown.push(itemId)
            }
            return { list, unknown }
          },
          { list: [], unknown: [] }
        )

        return <UserSelectValues value={list} unKnownValues={unknown} />
      }}
    </UserSelectFieldStyled>
  )
}

UserSelectField.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
    })
  ),
}

export default injectIntl(UserSelectField)
