export default {
  all: 'Alle',
  active: 'Actief',
  hideInactive: 'Verberg inactief',
  action: 'Actie',
  clickToCopy: 'Klik om te kopieren',
  copiedToClipboard: 'De waarde is gekopieerd naar je klembord',
  duplicate: 'Dupliceren',
  deepDuplicate: 'Dupliceren inclusief subdashboards',
  moveTo: 'Verplaats naar',
  remarks: 'Opmerkingen',
  delete: 'Verwijderen',
  confirm: 'Bevestigen',
  save: 'Opslaan',
  clear: 'Leeg maken',
  cancel: 'Annuleren',
  enabled: 'Ingeschakeld',
  enable: 'Ingeschakelen',
  disable: 'Uitschakelen',
  continue: 'Verdergaan',
  create: 'Creëren',
  deleteSuccess: '{name} is succesvol verwijderd',
  isRequired: ' is verplicht',
  confirmMessage: 'Ben je zeker?',
  confirmVehicleDisable: 'Weet u zeker dat u het voertuig wilt uitschakelen?',
  deleteConfirmationMessage: 'Weet je zeker dat je dit item wilt verwijderen: ',
  unsubscribeConfirmationMessage: 'Weet u zeker dat u deze dashboardgroep niet meer wil volgen: ',
  deleteRenewMessage: 'Weet je zeker dat je dit item wilt herverzenden: ',
  deleteItemSuccess: 'Item is succesvol verwijderd',
  deleteItemError: 'Kan het item niet verwijderen',
  deleteItemViolationError: 'Kan het item niet verwijderen. Het is gekoppeld aan andere records.',
  duplicateConfirmationMessage: 'Weet je zeker dat je volgende item wilt dupliceren: ',
  duplicateItemSuccess: 'Item is succesvol gedupliceerd',
  duplicateItemError: 'Kan het item niet dupliceren',
  duplicateItemStart: 'Dupliceren van {name} is gestart',
  updateSuccess: ' is succesvol bijgewerkt',
  updateItemSuccess: 'Item succesvol opgeslagen',
  updateItemError: 'Kan het item niet opslaan',
  insertItemSuccess: 'Item met succes aangemaakt',
  insertItemError: 'Kan het item niet maken',
  pasteItemSuccess: '{name} is succesvol geplakt',
  pasteItemError: 'Er ging iets mis bij het plakken van {name}',
  confirmDiscardChanges: 'Weet je zeker dat je je wijzigingen wilt weggooien?',
  selected: 'Geselecteerd',
  loadnig: 'Bezig met laden',
  multiply: 'Vermenigvuldigen',
  devide: 'Delen',
  copyFfDefaults: 'Importeer defaults',
  data: 'Gegevens',
  historical: 'Historisch',
  transparancy: 'Transparantie',
  benchmark: 'Benchmark',
  date: 'Datum',
  activationDate: 'Activeer datum',
  export: 'Exporteer',
  export_xlsx: 'Exporteer XLSX',
  export_docx: 'Exporteer DOCX',
  site: 'Site',
  building: 'Gebouw',
  country: 'Land',
  department: 'Afdeling',
  dateOfBirth: 'Geboortedatum',
  yearOfBirth: 'Geboortejaar',
  gender: 'Geslacht',
  highestDegreeOfEducation: 'Hoogst behaalde diploma',
  filter: 'Filter',
  clearFilter: 'Filter wissen',
  download: 'Downloaden',
  reload: 'Herladen',
  add: 'Toevoegen',
  import: 'Importeren',
  vehicle: 'Voertuig',
  vehicleType: 'Voertuig type',
  power: 'Vermogen',
  fuelConsumptionCombined: 'Gecombineerd brandstofverbruik',
  energyConsumptionCombined: 'Gecombineerd energieverbruik',
  co2Emission: 'CO2 emissie',
  maxLoadCapacity: 'Max laadvermogen',
  fuelType: 'Brandstoftype',
  vehicleFuelType: 'Brandstoftype voertuig',
  organisation: 'Organisatie',
  organisationalSturctureType: 'Organisatiestructuur type',
  entity: 'Entiteit',
  value: 'Waarde',
  internalId: 'Intern ID',
  constructionYear: 'Bouwjaar',
  consumption: 'Consumptie',
  mileage: 'Kilometers',
  consumptionInputPreference: 'Verbruik invoervoorkeur',
  createNewVersion: 'Maak een nieuwe versie',
  title: 'Titel',
  subTitle: 'Ondertitel',
  default: 'Standaard',
  contract: 'Contract',
  key: 'Sleutel',
  items: 'Items',
  description: 'Beschrijving',
  order: 'Volgorde',
  parent: 'Ouder',
  label: 'Label',
  color: 'Kleur',
  page: 'Pagina',
  row: 'Rij',
  name: 'Naam',
  product: 'Product',
  productionRelated: 'Productie gerelateerd',
  finishingLevel: 'Afwerkingsniveau',
  supplier: 'Leverancier',
  reportingPeriod: 'Verslagperiode',
  totalEmission: 'Totale emissie',
  extra: 'Extra',
  datapoint: 'Datapunt',
  settings: 'Instellingen',
  equipment: 'Apparatuur',
  exportAsExcel: 'Exporteer naar Excel ',
  exportAsWord: 'Exporteer naar Word',
  yes: 'Ja',
  no: 'Nee',
  lastValue: 'Laatste waarde',
  direction: 'Richting',
  category: 'Categorie',
  selectCategory: 'Select categorie',
  consumer: 'Verbruiker',
  activity: 'Activiteit',
  transportMode: 'Vervoer mode',
  transportLoadType: 'Transportbelastingstype',
  travelMethod: 'Reismethode',
  typeLogistics: 'Type logistiek',
  image: 'Afbeelding',
  type: 'Type',
  createdAt: 'Aangenmaakt op',
  electMultipleItems: 'Selecteer meerdere items',
  duplicateSelected: 'Dupliceer {count} item(s) hier',
  moveSelected: 'Verplaats {count} item(s) naar hier',
  bulkDuplicateItemsSuccess: '{count} item(s) gedupliceerd',
  bulkMoveItemsSuccess: '{count} item(s) verplaatst',
  maxFavouritesReached: 'Maximum van {amount} favorieten is bereikt.',
  inCompleteForm: 'Gelieve alle verplichte velden in te vullen',
  sortBy: 'Sorteer op',
  parentOrganisation: 'Bovenliggende organisatie',
  childOrganisation: 'Onderliggende organisatie',
  percentage: 'Percentage',
  financialControl: 'Financiële controle',
  jointFinancialControl: 'Gezamenlijke financiële controle',
  operationalControl: 'Operationele controle',
  ownership: 'Eigendom',
  info: 'info',
  joint: 'Gezamenlijk',
  equityShare: 'Aandeel',
  equitySharePercentage: 'Aandeel percentage',
  organisationDraftMode:
    'U bevindt zich in de conceptmodus, selecteert een datum om de conceptmodus te verlaten.',
  percentageExceeds: 'Het totale percentage overschrijdt {value}%',
  greaterThen: 'Moet groter zijn dan {value}',
  franchisee: 'Franchisee',
  franchisor: 'Franchisegever',
  lessor: 'Verhuurder',
  lessee: 'Huurder',
  investor: 'Investeerder',
  investee: 'Geïnvesteerde',
  combinationWithFieldAlreadyExists: 'Combinatie met {field} bestaat al',
  environmentType: 'Type omgeving',
  distributed: 'Gedistribueerd',
  sharedOwnership: 'Gedeeld eigendom',
  remark: 'Opmerking',
  dateRequired: 'Datum is verplicht',
  totalExceedsAmount: 'Het totaal is meer dan {amount}',
  canNotBeTheSameAs: 'Kan niet hetzelfde zijn als {value}',
  details: 'Details',
  showDetails: 'Bekijk details',
  consumptionValue: 'Verbruikswaarde',
  'consumptionValue.info': 'Waarde invoeren als meterstand (Uit) of als verbruikswaarde (Aan)',
  locked: 'Vergrendeld',
  open: 'Open',
  updateError: 'Je wijzigingen konden niet opgeslagen worden.',
  startDate: 'Start datum',
  endDate: 'Eind datum',
  period: 'Period',
  status: 'Status',
  license: 'Licentie',
  noModulesFound: 'Geen modules gevonden',
  unlimited: 'Ongelimiteerd',
  min: 'Min',
  max: 'Max',
  timestamp: 'Timestamp',
  user: 'Gebruiker',
  operation: 'Actie',
  customer: 'Klant',
  switchedToCustomer: 'Overgeschakeld naar {customer}',
  application: 'Applicatie',
  version: 'Versie',
  versions: 'Versies',
  deployedAt: 'Geïnstalleerd op',
  shortcuts: 'Snelkoppelingen',
  dashboard: 'Dashboard',

  boundaries: 'Grenzen',
  activities: 'Activiteiten',
  ghgSettings: 'GHG instellingen',
  projectTeam: 'Project team',
  successfullySaved: 'Wijzigingen bewaard.',
  squealThreshold: 'Squeal drempel',
  sendMail: 'Verzend mail',
  sendSms: 'Verzend SMS',
  sendVoiceMessage: 'GSM bellen',
  sendVoiceFixedMessage: 'Vaste lijn bellen',
  doNothing: 'Geen actie',
  noFlowLogic: 'Gelieve tenminste één conditie te definiëren.',
  if: 'Als',
  then: 'Dan',
  elseIf: 'Of als',
  else: 'Anders',
  to: 'naar',
  show: 'Tonen',

  'reportingYearLock.update.success': 'Rapporteringsjaar wijzigingen zijn opgeslagen',

  'organisation.structure.state.update.success': 'Staat is opgeslagen',
  'organisation.structure.state.create.error':
    'Fout bij het creëren van een nieuwe organisatiestructuur',
  'organisation.structure.state.update.error': 'Fout bij het bijwerken van organisatiestructuur',
  'organisation.structure.state.update.error.percentage': 'Sommige percentages overschrijden 100%',
  'organisation.structure.state.edge.error.onlyOne': 'Slechts één relatie mogelijk',

  'organisation.env.state.create.success': 'Nieuwe conceptmodus is aangemaakt',
  'organisation.env.state.create.error':
    'Fout bij het createn van de nieuwe organisatie omgeving status',
  'organisation.env.state.update.success': 'Status is opgelsagen',
  'organisation.env.state.update.error': 'Fout bij het bijwerken van de data',
  'organisation.env.state.update.error.percentage': 'Sommige percentages overschrijden 100%',

  'error.entity.samePresetExistsInSite':
    'Een entiteit met dezelfde preset bestaat al op deze site. Slechts één entiteit van dit type is toegestaan.',

  'noAccess.title': 'Geen toegang',
  'noAccess.subTitle': 'Sorry, u bent niet gemachtigd om toegang te krijgen tot deze pagina.',

  // Entity
  'entity.type.department': 'Afdeling',
  'entity.type.building': 'Gebouw',
  'entity.type.site': 'Site',
  'entity.type.fuel': 'Brandstof',
  'entity.type.fuelType': 'Brandstoftype',

  // Dashboard components
  'calendarComponent.unsavedChange': 'niet opgeslagen wijzigingen...',
  'calendarComponent.savedChanges': 'wijzigingen opgeslagen',
  'calendarComponent.save': 'Opslaan',
  'connectivity.title': 'CONNECTIVITEIT',
  'connectivity.isOnline': 'is online',
  'connectivity.lastConnection': 'laatste verbinding',
  'connectivity.ago': 'geleden',
  'graphComponent.presetControl': 'Preset',
  'graphComponent.startTimeControl.startTime': 'Starttijd',
  'graphComponent.endTimeControl.endTime': 'Eindtijd',
  'graphComponent.granularyControl.granularity': 'Granulariteit',
  'graphComponent.presetControl.thisDay': 'Vandaag',
  'graphComponent.presetControl.linkedWidget': 'Gekoppelde widget',
  'graphComponent.presetControl.thisWeek': 'Deze week',
  'graphComponent.presetControl.thisMonth': 'Deze maand',
  'graphComponent.presetControl.thisYear': 'Dit jaar',
  'graphComponent.presetControl.week': 'Week',
  'graphComponent.presetControl.month': 'Maand',
  'graphComponent.presetControl.year': 'Jaar',
  'graphComponent.presetControl.pastDay': 'Afgelopen 24 uur',
  'graphComponent.presetControl.past7Days': 'Afgelopen 7 dagen',
  'graphComponent.presetControl.past21Days': 'Afgelopen 21 dagen',
  'graphComponent.presetControl.past28Days': 'Afgelopen 28 dagen',
  'graphComponent.presetControl.previousWeek': 'Vorige week',
  'graphComponent.presetControl.previousMonth': 'Vorige maand',
  'graphComponent.presetControl.previousYear': 'Vorig jaar',
  'graphComponent.presetControl.yesterday': 'Gisteren',
  'graphComponent.presetControl.dayBefore': 'Eergisteren',
  'graphComponent.presetControl.thisDayLastWeek': 'Deze dag vorige week',
  'graphComponent.presetControl.customPeriod': 'Aangepaste periode',
  'graphComponent.presetControl.customRange': 'Aangepast bereik',
  'graphComponent.presetControl.customDate': 'Aangepaste datum',
  'graphComponent.presetControl.none': 'Geen',
  'graphComponent.presetControl.start': 'Begin',
  'graphComponent.presetControl.end': 'Einde',
  'graphComponent.rawDataControl.rawData': 'Ruwe Data',
  'graphComponent.tooltip.totals': 'Totalen',
  'severityComponent.critical.criticalIssues': 'Kritieke problemen',
  'severityComponent.severe.severeIssues': 'Ernstige problemen',
  'severityComponent.warning.warnings': 'Waarschuwingen',
  'severityComponent.details': 'Details',
  'severityComponent.overview': 'Overzicht',
  'squealHeatmapComponent.heatMap.selectSqueal': 'Selecteer squeal om te inspecteren',
  'squealOverviewComponent.acknowledge': 'Bevestigen',
  'squealOverviewComponent.viewHistory': 'Bekijk geschiedenis',
  'squealOverviewComponent.startTime': 'Starttijd',
  'squealOverviewComponent.cost': 'Kost',
  'squealOverviewComponent.severity': 'Impact',
  'squealOverviewComponent.name': 'Naam',
  'squealOverviewComponent.confirmAcknowledge.title':
    'Bent u zeker dat u de squeal wilt bevestigen?',
  'squealOverviewComponent.confirmAcknowledge.subtitle':
    'Deze zal niet meer getoond worden tenzij de storing zich opnieuw voordoet.',
  'squealRemarksComponent.cancel': 'Annuleren',
  'squealRemarksComponent.confirm': 'Bevestigen',
  'squealRemarksComponent.justifyAck': 'Verantwoording bevestigen van squeal',
  'squealRemarksComponent.nothingToRemark': 'Geen opmerkingen te maken voor deze squeal',
  'squealRemarksComponent.remarkInput.period': 'Periode',
  'squealRemarksComponent.remarkInput.peakVisitors': 'Piek aantal bezoekers',
  'squealRemarksComponent.remarkInput.peakOccurredOn': 'Piek vond plaats op',
  'squealRemarksComponent.remarkInput.remarkRequired': 'Gelieve een opmerking in te vullen',
  'squealRemarksComponent.remarkInput.remark': 'Opmerking...',
  'squealTrendComponent.selectSqueal':
    'Selecteer een heatmap-item om de trend van de squeal te bekijken',
  'consumptionExport.reportSent': 'Rapport verzonden',
  'consumptionExport.reportFailed': 'Rapport kon niet worden gegenereerd',
  'dashboard.error.title': 'Dit dashboard werkt helaas niet',
  'dashboard.error.subTitle': 'We kunnen dit dashboard niet weergeven door een error.',

  'widget.error.title': 'Error',
  'widget.error.subTitle': `Widget kan niet weergegeven worden`,

  //AgEventLog Widget
  'widget.agEventLog.header.beginTime': 'Begin',
  'widget.agEventLog.header.endTime': 'Einde',
  'widget.agEventLog.header.name': 'Naam',
  'widget.agEventLog.header.tag': 'Tag',
  'widget.agEventLog.header.lastValue': 'Laatste waarde',
  'widget.agEventLog.header.inUse': 'In bedrijf',
  'widget.agEventLog.header.error': 'Error',
  'widget.agEventLog.header.alarm': 'Alarm',
  'widget.agEventLog.header.duration': 'Duurtijd',
  'widget.agEventLog.currentState': 'Huidige waarde',

  // Settings components
  'entityTypeManager.entityTypeConfig.name': 'Naam',
  'entityTypeManager.entityTypeConfig.marker': 'Marker',
  'entityTypeManager.entityTypeConfig.tag': 'Tag',
  'entityTypeManager.entityTypeConfig.field': 'Field',
  'entityTypeManager.entityTypeConfig.confirm': 'Bevestigen',
  'entityTypeManager.entityTypeConfig.saveChanges': 'Opslaan',
  'entityTypeManager.entityTypeConfig.discardChanges': 'Ongedaan maken',
  'entityTypeManager.entityTypeConfig.translations': 'Vertalingen (optioneel)',
  'entityTypeManager.entityTypeConfig.unsavedChanges': 'Er zijn niet-opgeslagen wijzigingen',
  'entityTypeManager.entityTypeConfig.unsavedChangesVerbose':
    'Wilt u de aangebrachte wijzigingen opslaan of ongedaan maken?',
  'entityTypeManager.entityTypeConfig.englishRequired': 'De engelse naam is vereist',
  'entityTypeManager.entityTypeConfig.required': 'Vereist',
  'entityTypeManager.entityTypeConfig.tagName': 'Tag naam',
  'entityTypeManager.entityTypeConfig.tagType': 'Tag type',
  'entityTypeManager.entityTypeConfig.typeRequired': 'Tag type is vereist',
  'entityTypeManager.entityTypeConfig.nameRequired': 'Tag naam is vereist',
  'entityTypeManager.entityTypeConfig.discardedChanges': 'Wijzigingen ongedaan gemaakt',
  'entityTypeManager.entityTypeConfig.somethingWentWrong': 'Er liep iets mis',
  'entityTypeManager.entityTypeConfig.invalidPreset':
    'De entiteit preset is ongeldig, niet opgeslagen.',
  'entityTypeManager.entityTypeConfig.createEntity': 'Toevoegen nieuwe entiteit',
  'entityTypeManager.entityTypeConfig.updateEntity': 'Updaten van entiteit',
  'entityTypeManager.entityTypeConfig.confirmDeleteEntity': 'Bevestig verwijdering',
  'entityTypeManager.entityTypeConfig.createdEntity': 'Nieuwe entiteit succesvol opgeslagen',
  'entityTypeManager.entityTypeConfig.updatedEntity': 'Entiteit succesvol gewijzigd',
  'entityTypeManager.entityTypeConfig.deletedEntity': 'Entiteit succesvol verwijderd',
  'entityTypeManager.entityTypeConfig.entityType': 'Entiteit type',
  'entityTypeManager.entityTypeConfig.entityTypeSite': 'Locatie',
  'entityTypeManager.entityTypeConfig.entityTypeBuilding': 'Gebouw',
  'entityTypeManager.entityTypeConfig.entityTypeFloor': 'Verdieping',
  'entityTypeManager.entityTypeConfig.entityTypeSpace': 'Gebied',
  'entityTypeManager.entityTypeConfig.entityTypeRoom': 'Ruimte',
  'entityTypeManager.entityTypeConfig.entityTypeEquipment': 'Toestel',
  'entityTypeManager.entityTypeConfig.entityTypeDatapoint': 'Data punt',
  'entityTypeManager.entityTypeConfig.deleteTheFollowing': 'Wenst u het volgende te verwijderen?',
  'entityTypeManager.entityTypeConfig.ofType': 'van het type',
  'entityTypeManager.entityTypeConfig.unnamed': 'Nieuw data punt',
  'entityTypeManager.entityTypeConfig.titleDatapointConfigurator': 'Data punt configuratie',
  'entityTypeManager.entityTypeConfig.datapointName': 'Data punt naam',
  'entityTypeManager.tagsForm.noWhitespaces': 'Geen spaties toegestaan',
  'entityTypeManager.tagsForm.reservedKeyword': 'Gereserveerd trefwoord',
  'entityTypeManager.tagsForm.noDuplicateTags': 'Geen dubbele tags toegestaan',

  'datapointsOverview.exportCSV': 'Download alle data punten als een CSV bestand',
  'datapointsOverview.manageEntities': 'Beheer entiteiten',
  'datapointsOverview.reload': 'Herlaad alle data punten',
  'datapointsOverview.editDatapoint': 'Bewerk data punt',
  'datapointsOverview.deleteDatapoint': 'Verwijder data punt',
  'datapointsOverview.clearFilters': 'Verwijder alle filters en sorteringen',

  'entityConfigurator.entityForm.entityName': 'Naam',
  'entityConfigurator.entityForm.nameRequired': 'Naam is vereist',
  'entityConfigurator.entityForm.entityPreset': 'Entiteit preset',
  'entityConfigurator.entityForm.presetRequired': 'Gelieve een preset te selecteren',
  'entityConfigurator.entityForm.tagValueRequired': 'Tag waarde is vereist',
  'entityConfigurator.entityForm.tagValue': 'Tag waarde',
  'entityConfigurator.entityForm.updatedEntity': 'werd succesful bijgewerkt',
  'entityConfigurator.entityForm.createdEntity': 'werd succesful gecreëerd',
  'entityConfigurator.entityForm.unitRequired': 'Gelieve een eenheid te selecteren',
  'entityConfigurator.entityForm.unit': 'Eenheid',

  // User Overview
  'userOverview.admin': 'Administrator',
  'userOverview.noData': 'Geen data',

  // Role Overview
  roleOverview: 'Rollen overzicht',
  'roleOverview.role': 'Rol',
  'roleOverview.permissions': 'Permissies',
  'roleOverview.providesAccess': 'Geeft toegang tot',
  'roleOverview.and': 'en',
  'roleOverview.noPermissions': 'Geen permissies',
  'roleOverview.view': 'Bekijk',
  'roleOverview.edit': 'Bewerk',
  'roleOverview.invalid': 'Ongeldige permissie',
  'roleOverview.canNotDeleteAdminRole': 'Adminrol kan niet verwijderd worden',

  // Role Modal
  'roleModal.addPermission': 'Permissie toevoegen',
  'roleModal.accessibleDatapoints': 'Zichtbare datapunten voor',
  'roleModal.roleName': 'Rol naam',
  'roleModal.createdRole': 'werd succesvol gecreëerd',
  'roleModal.updatedRole': 'werd succesvol bijgewerkt',
  'roleModal.roleNameRequired': 'Rol naam is vereist',
  'roleModal.editRole': 'Bewerk rol',
  'roleModal.newRole': 'Nieuwe rol',

  // Customer overview
  'customerOverview.create': 'Nieuwe customer',

  // Permissions Modal
  'permissionsModal.step1': 'Stap 1',
  'permissionsModal.step1description': 'Verleen toegang tot...',
  'permissionsModal.step1ToolTip':
    'Wanneer een entiteit geselecteerd wordt in de onderstaande boomweergave, geeft dit toegang tot die entiteit en al zijn kinderen. Beschikbare tags in stap 2 worden bijgewerkt bij het selecteren van een entiteit.',
  'permissionsModal.step2': 'Stap 2',
  'permissionsModal.step2description': 'Waar volgende tags & markers...',
  'permissionsModal.step2ToolTip':
    'Wanneer tags uit de onderstaande lijst worden geselecteerd, wordt de permissie beperkt tot de entiteiten die alle geselecteerde tags hebben. Het selecteren van meer tags zal als gevolg resulteren in een kleinere set datapunten waar de permissie toegang toe zal geven, dit is zichtbaar in stap 3.',
  'permissionsModal.step3': 'Stap 3',
  'permissionsModal.step3description': 'Voorbeeld van de datapunten',
  'permissionsModal.step3ToolTip':
    'Het selecteren van een entiteit en tags zal resulteren in een set datapunten waartoe deze permissie toegang zal geven. Deze dataputen kunnen hieronder worden bekeken.',
  'permissionsModal.searchTags': 'Geselecteerde entiteit en tags voor permissie',
  'permissionsModal.availableTags': 'Beschikbare tags voor',
  'permissionsModal.selectedForPermission': 'Geselecteerde tags voor permissie',
  'permissionsModal.updatingPermission': 'Permissie bijwerken',
  'permissionsModal.creatingPermission': 'Permissie creëren',
  'permissionsModal.updateFailed': 'Het bijwerken van de permissie is mislukt',
  'permissionsModal.creationFailed': 'Het creëren van de permissie is mislukt',
  'permissionsModal.updateSuccess': 'De permissie werd succesvol bijgewerkt',
  'permissionsModal.creationSuccess': 'De permissie werd succesvol gecreëerd',
  'permissionsModal.editPermission': 'Bewerk permissie',
  'permissionsModal.newPermission': 'Nieuwe permissie',
  'permissionsModal.for': 'voor',
  'permissionsModal.permission': 'Permissie',
  'permissionsModal.companyEntities': 'Entiteiten van de organisatie',
  'permissionsModal.externalEntities': 'Externe entiteiten',

  //Support Modal
  'support.title': 'Support',
  'contact.title': 'Contact',
  'support.succes.message': 'Mail is verzonden',
  'support.error.message': 'Error tijdens het verzenden van je mail',
  'label.name': 'Naam',
  'label.surName': 'Achternaam',
  'label.email': 'E-mail',
  'label.phone': 'Telefoonnummer',
  'label.title': 'Titel',
  'label.description': 'Omschrijving',
  'submit.form': 'Verzenden',
  'cancel.form': 'Annuleren',
  'support.headertext':
    'Gelieve onderstaand formulier in te vullen en we zullen zo spoedig mogelijk contact met U opnemen betreffende Uw support vraag.',
  'contact.headertext':
    'Gelieve onderstaand formulier in te vullen en we zullen zo spoedig mogelijk contact met U opnemen betreffende Uw vraag.',

  // Overview pagenames
  'customers.overviewPage.name': 'Overzicht customers',
  'datapoints.overviewPage.name': 'Overzicht datapoints',
  'users.overviewPage.name': 'Overzicht gebruikers',

  // compliance Modal
  'title.compliance': 'Nakoming',

  // Confidential information modal
  'title.confidential.information': 'Data verwerking, privacy en website gebruik',
  'title.confidential.informationShort': 'General policy',

  // Terms and Conditions
  'termsAndConditions.title': 'General Terms and Conditions',

  // Tag Container
  'tagContainer.noMoreTagsFor': 'Geen tags meer beschikbaar voor',
  'tagContainer.noTagsPleaseSelect':
    'Gelieve een entity te selecteren om de beschikbare tags weer te geven',

  // Dashboard modal
  'menu.dashboardForm.dashboardName': 'Dashboard naam',
  'menu.dashboardForm.nameRequired': 'Dashboard naam is vereist',
  'menu.dashboardForm.iconRequired': 'Dashboard icoon is vereist',
  'menu.dashboardForm.positionDashboard': 'Dashboard positie',
  'menu.dashboardForm.positionHelp':
    '(Optioneel) Selecteer rechts een dashboard om van dit nieuwe dashboard een kinderdashboard van het geselecteerde dashboard te maken. Laat leeg om een dashboard te maken dat geen hiërarchische relatie heeft met andere dashboards.',
  'menu.dashboardForm.positionPlaceholder': '(Optioneel) Selecteer bovenliggend dashboard',
  'menu.dashboardForm.favouriteDashboard': 'Toevoegen aan favorieten',
  'menu.dashboardForm.icon': 'Dashboard Icoon',
  'menu.dashboardForm.creationFailed': 'Het creëren van het dashboard is mislukt',
  'menu.dashboardForm.creationSuccess': 'Het dashboard werd succesvol gecreëerd',
  'menu.dashboardForm.emptyTitle': 'Een title moet uit minstens 1 karakter bestaan.',
  'menu.dashboardForm.updateFailed': 'Het updaten van het dashboard is mislukt',
  'menu.dashboardForm.updateSuccess': 'Het dashboard werd succesvol geupdate',
  'menu.dashboardForm.removeFailed': 'Het verwijderen van het dashboard is mislukt',
  'menu.dashboardForm.removeSuccess': 'Het dashboard is succesvol verwijderd',
  'menu.dashboardForm.dragFailed': 'Het updaten van de dashboards is mislukt',
  'menu.dashboardForm.dragSuccess': 'De dashboards werden succesvol geupdate',
  'menu.dashboardTree.createTopLevelItem': 'Maak een top-level dashboard',
  'menu.dashboardCreate': 'Dashboard maken',
  'menu.dataEntryCreate': 'Dashboard voor gegevensinvoer maken',

  // Terms of use
  'title.cookies': 'Cookies',

  // Units Overview
  unitsOverview: 'Eenheden overzicht',
  'unitEdit.editUnit': 'Eenheid bijwerken',
  'unitEdit.updateFailed': 'Het bijwerken van de eenheid is mislukt',
  'unitEdit.updateSuccess': 'De eenheid werd succesvol bijgewerkt',
  'unitCreate.newUnit': 'Nieuwe eenheid',
  'unitCreate.creationFailed': 'Het creëren van de eenheid is mislukt',
  'unitCreate.creationSuccess': 'De eenheid werd succesvol gecreëerd',
  'unitForm.unitName': 'Eenheid naam',
  'unitForm.nameRequired': 'Gelieve de naam voor de eenheid in te vullen',
  'unitForm.unitFormat': 'Eenheid formaat',
  'unitForm.unitType': 'Type',

  // Data table
  'dataTable.reload': 'Herlaad alles',
  'dataTable.manage': 'Beheer',
  'dataTable.exportCSV': 'Download als CSV bestand',
  'dataTable.edit': 'Bewerken',
  'dataTable.delete': 'Verwijderen',
  'dataTable.close': 'Sluiten',
  'dataTable.cancel': 'Annuleren',
  'dataTable.save': 'Opslaan',
  'dataTable.saveAndClose': 'Opslaan en sluiten',
  'dataTable.new': 'Nieuwe',
  'dataTable.clearFilters': 'Verwijder alle filters en sorteringen',
  'dataTable.lastValue': 'Laatste waarde',
  'datapointsOverview.datapointSingle': 'Datapunt',
  'datapointsOverview.datapointPlural': 'Datapunten',
  'usersOverview.userSingle': 'Gebruiker',
  'usersOverview.userPlural': 'Gebruikers',

  // Layout components
  'settings.themeSettings': 'Thema Instellingen',
  'settings.collapsedMenu': 'Samengevouwen menu',
  'settings.menuShadow': 'Menu schaduw',
  'settings.lightTheme': 'Licht thema',
  'settings.borderlessCards': 'Randloze kaarten',
  'settings.squaredBorders': 'Rechthoekige randen',
  'settings.fixedWidth': 'Vaste breedte',
  'topBar.profileMenu.hello': 'Hallo {name}',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.theme': 'Thema',
  'topBar.profileMenu.editProfile': 'Profiel wijzigen',
  'topBar.profileMenu.logout': 'Uitloggen',
  'topBar.profileMenu.preferences': 'Voorkeuren',
  'topBar.profileMenu.advanced': 'Geavanceerd',
  'menu.favourites': 'Favorieten',
  'menu.dashboard': 'Dashboard',
  'menu.dashboards': 'Dashboards',
  'menu.dataEntry': 'Gegevensinvoer',
  'menu.dashboards.edit': 'Dashboards beheren',
  'menu.dashboard.shareDashboard': 'Dashboards delen',
  'menu.dashboard.share': 'Delen',
  'menu.dashboard.shareFailed': 'Delen van dashboards is mislukt',
  'menu.dashboard.shareSuccess': 'Dashboard(s) werden succesvol gedeeld',
  'menu.dashboard.newDashboard': 'Beheer dashboards',
  'menu.settings': 'Instellingen',
  'menuBar.logoDropdown.currentCustomer': 'Huidige klant',
  'layout.globalTimeSelection': 'Globale tijdinstelling',
  'layout.sectionTimeSelection': 'Sectie tijdinstelling',

  // Share Dashboard
  'shareDashboard.selectDashboards': 'Dashboard selectie',
  'shareDashboard.shareWith': 'Deel de dashboards met',
  'shareDashboard.permissionLevel': 'Permissielevel',
  'shareDashboard.allowEdit': 'Master dashboard groep',
  'shareDashboard.affectedUsers': 'Gelinkte gebruikers',
  'shareDashboard.usersByMailAddress': 'Gebruikers per mailadres',
  'shareDashboard.knownUsers': 'Gekende gebruikers',

  // Delete modal
  'modal.delete.success': 'Succesvol verwijderd',
  'modal.delete.error': 'Er ging iets mis',

  // Invite page
  'invite.welcomeToAviary': 'Welkom op Canary',

  // General
  'general.unsavedChanges': 'Er zijn niet-opgeslagen wijzigingen',
  'general.savedChanges': 'Alle wijzigingen zijn opgeslagen',
  'general.noChangesToSave': 'Geen wijzigingen om op te slagen',
  'general.save': 'Opslaan',
  'general.cancel': 'Annuleren',
  'general.yes': 'Ja',
  'general.no': 'Nee',
  'general.someValuesAreToHeigh': 'Sommige waarden zijn te hoog',
  'general.incorrectPassword': 'Password is incorrect',
  'general.inputOwnPassword': 'Gelieve uw eigen wachtwoord in te geven',

  // Languages
  'language.dutch': 'Nederlands',
  'language.english': 'Engels',
  'language.french': 'Frans',
  'language.nl-NL': 'Nederlands',
  'language.en-US': 'Engels',
  'language.fr-FR': 'Frans',

  // Report
  'report.create': 'Genereer rapport',
  'report.site': 'Site',
  'report.address': 'Address',
  'report.contact': 'Contactpersoon',
  'report.creationDate': 'Gegenereerd op',
  'report.scraper': 'Scraper',
  'report.phone': 'Telefoon',
  'report.openingTimes': 'Openingstijden',
  'report.visitorsInformation': 'Bezoekers informatie',
  'report.entered': 'IN',
  'report.exited': 'UIT',
  'report.max': 'MAX',
  'report.heatmap': 'Heatmap',
  'report.squeals': 'Squeals',
  'report.noSqueals': 'Geen Squeals',
  'report.none': 'Geen',
  'report.acknowledged': 'Bevestigd',
  'report.notAcknowledged': 'Niet bevestigd',
  'report.remark': 'Nota Squeal',
  'report.footer': 'This report was generated by Aviary® - a Canary® by product for',
  'report.waitForLoading': 'Even geduld aub - uw rapport wordt gegenereerd',

  // Error handling
  'global.message.somethingWentWrong': 'Er ging iets mis',

  // Calendar months
  'month.1': 'Januari',
  'month.2': 'Februari',
  'month.3': 'Maart',
  'month.4': 'April',
  'month.5': 'Mei',
  'month.6': 'Juni',
  'month.7': 'Juli',
  'month.8': 'Augustus',
  'month.9': 'September',
  'month.10': 'Oktober',
  'month.11': 'November',
  'month.12': 'December',

  // General Component Captions
  'componentCaptions.noMarkers': 'Geen markers',
  'componentCaptions.noTags': 'Geen tags',
  'componentCaptions.noDatapoints': 'Geen datapunten',
  'componentCaptions.noRules': 'Geen rules',
  'componentCaptions.noOrganisations': 'Geen organisaties',
  'componentCaptions.noResults': 'Geen resultaten',
  'componentCaptions.searchToSelect': 'Zoeken',
  'componentCaptions.noConditions': 'Geen condities',
  'componentCaptions.addCondition': 'Voeg conditie toe',

  'organisation.structure.type.EQUITY': 'Aandelen',
  'organisation.structure.type.FRANCHISE': 'Franchise',
  'organisation.structure.type.INVESTMENT': 'Investering',
  'organisation.structure.type.LEASING': 'Leasing',

  'organisation.env.bulkUpdateWarning':
    'U staat op het punt om alle items in deze groep bij te werken.',

  'organisation.env.type.ASSETS': 'Assets',
  'organisation.env.type.VEHICLES': 'Voertuigen',
  'organisation.env.type.UPSTREAM': 'Upstream/Downstream Activities',

  'organisation.environments.type.CORE': 'Core',
  'organisation.environments.type.FRANCHISE': 'Franchise',
  'organisation.environments.type.LEASING': 'Leasing',

  'reportingYear.status.INCOMPLETE': 'Onvolledig',
  'reportingYear.status.COMPLETE': 'Volledig',
  'reportingYear.status.IN_PROGRESS': 'Bezig',

  'reportingYear.datepicker.info':
    'Geen gegevensinvoer toegestaan voor rapportageperioden die vergrendeld zijn.',

  'search.goto.placeholder': "Zoek naar pagina's, vaste bestanden, dashboarden, ...",
  'search.switchCustomer.placeholder': 'Zoek een klant om naar over te schakelen',
  'search.noResultFound': 'Geen resultaten gevonden',

  'shortcut.GOTO': 'Ga naar',
  'shortcut.SWITCH_CUSTOMER': 'Wissel van klant',
  'shortcut.VERSIONS': 'Versies',
  'shortcut.CHEATSHEET': 'Spiek brief',
  'shortcut.CLOSE': 'Sluiten',

  'file.type.images': 'Afbeeldingen',
  'file.type.documents': 'Bestanden',

  'energyContracts.warning.missingContractsItems': '{count} items',
  'energyContracts.warning.missingContracts': 'missen een contract',
  'energyContracts.warning.missingContractItem': 'Een item',
  'energyContracts.warning.missingContract': 'ontbreekt een contract',
}
