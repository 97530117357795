import React, { useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { generateTagColor } from 'components/Dashboard/components/Tags/functions'
import ValuePicker from 'components/Form/components/ValuePicker'
import Button from 'components/Global/Button'

import { PROFILE_QUERY } from '../../../../services/user'
import CustomValueCreate from './CustomValueCreate'

const UserSelectValuePicker = ({
  value,
  unknownValues,
  options,
  onChange,
  onSearch,
  onAddCustomValue,
  onRemoveItem,
  disabled,
  //...fieldProps
}) => {
  const [searchValue, setSearchValue] = useState('')
  const { data } = useQuery(PROFILE_QUERY, {
    variables: {
      id: unknownValues?.[0],
    },
    skip: !unknownValues?.[0],
  })

  const unknown = useMemo(() => {
    if (data) {
      return [
        {
          ...data.account,
          name: `${data.account.firstname || ''} ${data.account.surname || ''} (${
            data.account.email
          })`,
        },
      ]
    }
    return []
  }, [data])

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue)
    onSearch(searchValue)
  }
  const handleAddCustomValue = (customValue) => {
    onAddCustomValue(customValue)
    setSearchValue('')
  }

  const allValues = [...unknown, ...value]

  return (
    <ValuePicker
      value={allValues}
      options={[...unknown, ...options]}
      search
      input
      variant="Tags"
      selectionKeys={{ label: 'name', value: 'id' }}
      isMultiSelect
      onChange={onChange}
      onSearch={handleSearch}
      width={380}
      valueElement={
        <div className="ValuePickerValue">
          {allValues.map(({ id, name }) => (
            <Button
              key={id}
              label={name}
              variant="tag"
              color={generateTagColor(name)}
              icon="fas fa-xmark"
              onIconClick={() => onRemoveItem({ id, name })}
              disabled={disabled}
            />
          ))}
        </div>
      }
    >
      <CustomValueCreate value={searchValue} onCreate={handleAddCustomValue} />
    </ValuePicker>
  )
}

UserSelectValuePicker.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
    })
  ),
}

export default UserSelectValuePicker
