import React, { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'

import FormStyled from 'components/Form/styled'

const Form = ({
  children,
  onSubmit,
  defaultValues,
  loading,
  shouldUnregister,
  mode,
  defaultEditMode = false,
  reValidateMode,
  className,
  createMode = false,
  id,
}) => {
  const [isEditMode, setEditMode] = useState(defaultEditMode)
  const formMethods = useForm({ defaultValues, shouldUnregister, mode, reValidateMode })

  const { reset, handleSubmit } = formMethods

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const handleFormSubmit = useCallback(
    ({ closeAfterSubmit = false } = {}) =>
      handleSubmit((data, event) =>
        onSubmit(data, { event, isEditMode, setEditMode, ...formMethods, closeAfterSubmit })
      ),
    [isEditMode, setEditMode, onSubmit, handleSubmit, formMethods]
  )

  const handleExecuteForm = useCallback((...params) => handleFormSubmit(...params)(), [
    handleFormSubmit,
  ])

  const handleOnSubmit = useMemo(() => handleFormSubmit(), [handleFormSubmit])

  const handleFormReset = useCallback(() => {
    formMethods.reset(defaultValues)
  }, [defaultValues, formMethods])

  return (
    <FormStyled className={className} id={id}>
      <FormProvider
        {...formMethods}
        isEditMode={isEditMode}
        setEditMode={setEditMode}
        isCreateMode={createMode}
        loading={loading}
        executeFormSubmit={handleExecuteForm}
        handleFormReset={handleFormReset}
      >
        <form onSubmit={handleOnSubmit}>
          {typeof children === 'function'
            ? children({
                isEditMode,
                setEditMode,
                isCreateMode: createMode,
                executeFormSubmit: handleExecuteForm,
                ...formMethods,
              })
            : children}
        </form>
      </FormProvider>
    </FormStyled>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
}

Form.defaultProps = {
  onSubmit: () => {},
  loading: false,
}

export default Form
