import React from 'react'

import BaseLayout from 'components/Layout/BaseLayout'
import AppUiProvider from 'components/Layout/UiProvider'

const NoLayout = ({ children }) => {
  return (
    <AppUiProvider>
      <BaseLayout>{children}</BaseLayout>
    </AppUiProvider>
  )
}

export default NoLayout
