import { useCallback, useMemo, useState } from 'react'

import { gql, useQuery } from '@apollo/client'
import { get, uniq } from 'lodash'

import { DASHBOARDS_QUERY } from 'services/dashboards'
import { PERMITTED_ENTITIES, PERMITTED_GEOCOORD_EQUIPS } from 'services/entities'
import { ENTITY_PRESET_LIST, ENTITY_PRESET_LIST_LANGUAGE } from 'services/entitypresets'
import { QUERY_AVAILABLE_FIXED_FILES } from 'services/fixedFiles'
import { QUERY_ORGANISATIONS } from 'services/organisations'
import { UNITS_QUERY } from 'services/units'

import { getLabel } from 'components/Global/FormField/utils/functions'

const getFilterOptions = (data, field) =>
  data ? uniq(data.map((record) => get(record, field))) : undefined

const isSearchMatch = (searchValue, record, searchKey, intl) => {
  if (!searchValue) {
    return true
  }
  const recordString = searchKey
    ? getLabel(record, searchKey, intl) || ''
    : typeof record === 'string'
    ? record
    : typeof record === 'number'
    ? record.toString()
    : ''

  return recordString.toLowerCase().includes(searchValue.toLowerCase())
}
const filterData = (data, filters, searchValue, searchKey, intl) => {
  if (data) {
    return data?.filter((record) => {
      const searchMatch = isSearchMatch(searchValue, record, searchKey, intl)
      return (
        searchMatch &&
        (!filters ||
          filters?.every((filter) => {
            if (filter.value === undefined) {
              return true
            }
            return get(record, filter.field) === filter.value
          }))
      )
    })
  }
}

export const useFilters = (data, config, events = {}, searchKey, intl, search) => {
  const [filterValues, setFilterValues] = useState({})
  const [searchValue, setSearchValue] = useState('')

  const onSearch = useCallback(
    (e) => {
      setSearchValue(e.target.value)
      search && search(e.target.value)
    },
    [setSearchValue, search]
  )

  const optionsById = useMemo(() => {
    return config?.reduce((map, { id, field }) => {
      map[id] = getFilterOptions(data, field)

      return map
    }, {})
  }, [data, config])

  const filters = useMemo(
    () =>
      config?.map(({ id, field, componentName, componentProps }) => {
        return {
          id,
          field,
          componentName,
          componentProps,
          options: optionsById[id],
          onChange: (value) => {
            setFilterValues((prev) => ({ ...prev, [id]: value }))
            if (events.onFilter) {
              events.onFilter({ id, value })
            }
          },
          value: filterValues[id],
        }
      }),
    [optionsById, config, filterValues, setFilterValues, events]
  )

  const filteredData = useMemo(
    () => (filters || searchValue ? filterData(data, filters, searchValue, searchKey, intl) : data),
    [data, filters, searchValue, searchKey, intl]
  )

  return { filters, onSearch, searchValue, data: filteredData }
}

export const DEFAULT_QUERY = gql`
  query default {
    default
  }
`

const queries = {
  DASHBOARDS_QUERY,
  DEFAULT_QUERY,
  ENTITY_PRESET_LIST,
  PERMITTED_ENTITIES,
  UNITS_QUERY,
  PERMITTED_GEOCOORD_EQUIPS,
  ENTITY_PRESET_LIST_LANGUAGE,
  QUERY_AVAILABLE_FIXED_FILES,
  QUERY_ORGANISATIONS,
}

export const useData = (queryObj, options = []) => {
  const query = queries[queryObj?.name] || DEFAULT_QUERY

  const { data, loading } = useQuery(query, {
    variables: queryObj?.variables,
    skip: query === DEFAULT_QUERY,
  })

  return { data: data?.[queryObj.dataKey] || options, loading }
}

const getDisplayValue = (options, selectionKeys, value, intl) => {
  const valueObject = getValueOption(value, options, selectionKeys) || value
  return valueObject
    ? selectionKeys
      ? getLabel(valueObject, selectionKeys.label, intl)
      : valueObject
    : null
}

export const useDisplayValue = (options, selectionKeys, value, intl) => {
  return useMemo(() => {
    if (!options) {
      return value
    }
    const isArrayValue = Array.isArray(value)
    if (isArrayValue) {
      return value.map((v) => getDisplayValue(options, selectionKeys, v, intl))
    }
    return getDisplayValue(options, selectionKeys, value, intl)
  }, [options, selectionKeys, value, intl])
}

export const getValueOption = (value, options, selectionKeys) => {
  return options.find((data) =>
    selectionKeys?.value
      ? get(data, selectionKeys.value) === value
      : typeof value === 'object'
      ? value?.id && data.id?.toString() === value.id.toString()
      : data === value
  )
}

const getValueObject = (value, valueKeys = []) =>
  valueKeys.reduce(
    (valueObject, key) => ({
      ...valueObject,
      [key]: value[key],
    }),
    {}
  )

const getSingleValue = ({ item, selectionKeys, valueKeys }) => {
  if (valueKeys) {
    return getValueObject(item, valueKeys)
  }
  return selectionKeys?.value ? get(item, selectionKeys.value) : item
}

const getMultiValue = ({ item, selectionKeys, valueKey, valueKeys, value }) => {
  const selectionKey = selectionKeys?.value || 'value'
  const currentValue = value || []
  return currentValue.some((i) => (valueKey ? i : get(i, selectionKey)) === get(item, selectionKey))
    ? currentValue.filter((i) => (valueKey ? i : get(i, selectionKey)) !== get(item, selectionKey))
    : currentValue.concat(
        valueKeys ? getValueObject(item, valueKeys) : valueKey ? get(item, valueKey) : item
      )
}

export const selectOption = ({
  item,
  value,
  valueKey,
  valueKeys,
  selectionKeys,
  isMultiSelect,
  returnAsArray,
  options,
}) => {
  const option = getValueOption(item, options, selectionKeys)
  return {
    option,
    newValue: isMultiSelect
      ? getMultiValue({ item: option, selectionKeys, valueKey, valueKeys, value })
      : returnAsArray
      ? [getSingleValue({ item: option, selectionKeys, valueKeys })]
      : getSingleValue({ item: option, selectionKeys, valueKeys }),
  }
}
