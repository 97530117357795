import React, { useMemo, useRef } from 'react'

import { useTheme } from 'styled-components'

import { useLocaleNumber } from 'util/numbers'

import { ActionConfirmationWithAdjustableValue } from 'components/Dashboard/components/ActionConfirmation'
import InlineErrors from 'components/Dashboard/components/Chart/InlineErrors'
import Button from 'components/Global/Button'
import Component from 'components/Global/Component/styled'
import Empty from 'components/Global/Empty'
import Icon from 'components/Global/Icon'
import { WidgetLoader } from 'components/Global/Loader/styled.WidgetLoader'
import { WidgetError } from 'components/Widgets/WidgetError'

import { StatusArrow, StatusCaption, StatusContainer, StatusStyled } from './styled'

const getCleanKpi = (value, variant, localizeNumber) => {
  if (value || value === 0) {
    return localizeNumber(value)
  }
  if (variant === 'Status') {
    return '-'
  }
  return undefined
}

const getDescription = (cleanKpi, unit, caption) => {
  let kpi = ''
  let title = ''
  if (cleanKpi !== undefined) {
    kpi = `<span class="value">${cleanKpi} <span class="unit">${unit || ''}</span></span>`
  }
  if (caption?.title) {
    title = `<span>${caption.title}</span>`
  }
  return `${kpi}${title}`
}

const Ui = ({
  caption,
  className,
  value,
  valueWidget,
  tags,
  prevValue,
  writeDatapoint = {},
  title,
  icon,
  line,
  unit,
  color,
  fontColor,
  arrowUpColor,
  arrowDownColor,
  url,
  target,
  size,
  loading,
  styleProps = {},
  showUnit = false,
  hideValue = false,
  showDescription = false,
  descriptionValue,
  onClick,
  onConfirmationRequest,
  confirmation,
  showDiffArrow = false,
  diffPercentage = false,
  forceKpiView = false,
  error,
  inlineErrors = [],
}) => {
  const container = useRef()
  const theme = useTheme()
  const localizeNumber = useLocaleNumber()

  const hasCaptionComponent = title || icon || !hideValue

  const cleanKpi = getCleanKpi(value, styleProps.variant, localizeNumber)
  const description = useMemo(() => {
    if (!showDescription || (!cleanKpi && !caption?.title)) {
      return ''
    }
    return getDescription(cleanKpi, unit, caption)
  }, [cleanKpi, caption, unit, showDescription])

  const diffValue = Math.round(((value - prevValue) / value) * 100)
  const informationWidget = !!styleProps.contextVariant
  const width = container.current?.clientWidth
  const height = container.current?.clientHeight
  const iconSize = width > 200 || height > 200 ? 'large' : 'small'
  return (
    <StatusStyled
      {...styleProps}
      className={`Status ${className}`}
      img={icon && icon.includes('.')}
      color={color || value?.color}
      fontColor={fontColor}
      line={line}
      clickable={!!(url || writeDatapoint.id)}
      toggleButton={!!writeDatapoint.id}
      size={size}
      ref={container}
      width={width}
      height={height}
      toggleState={value}
      hasIcon={!!icon}
      caption={caption}
      informationWidget={informationWidget}
      hideValue={hideValue}
    >
      <ActionConfirmationWithAdjustableValue
        {...confirmation}
        id={writeDatapoint.id}
        onConfirmationRequest={onConfirmationRequest}
        parentRef={container.current}
      >
        {
          <Icon
            icon={caption?.icon || icon}
            color={[theme.color.error, theme.color.success][confirmation?.value || 0]}
          />
        }
      </ActionConfirmationWithAdjustableValue>
      <StatusContainer
        className="StatusContainer"
        variant={styleProps.variant}
        informationWidget={informationWidget}
        as={url ? Button : 'div'}
        url={!writeDatapoint.id && url}
        target={target}
        onClick={onClick}
        img={icon && icon.includes('.')}
        hasLabel={!!title}
        hasKpi={forceKpiView || (!hideValue && cleanKpi !== undefined)}
        hasIcon={!!icon}
        caption={caption}
        hideValue={hideValue}
        line={line}
        valueWidget={valueWidget}
      >
        {hasCaptionComponent && (
          <Component as={informationWidget ? Empty : 'div'} className="Status__caption">
            <Component as={informationWidget ? Empty : 'div'} className="Status__title-container">
              {title && <p className="Title">{title}</p>}
              {icon && <Icon icon={icon} size={iconSize} />}
            </Component>
            {!hideValue && cleanKpi !== undefined && (
              <div className="Status__kpi-container">
                <p className={descriptionValue ? '' : 'kpi'}>
                  <span>{cleanKpi}</span>
                  {showUnit && <label>{unit}</label>}
                </p>
                {(diffPercentage || showDiffArrow) && prevValue !== undefined && (
                  <StatusArrow
                    className="StatusArrow"
                    diffValue={diffValue}
                    arrowUpColor={arrowUpColor}
                    arrowDownColor={arrowDownColor}
                    fontColor={fontColor}
                    showDiffArrow={showDiffArrow}
                  >
                    {diffPercentage ? (
                      <>
                        {localizeNumber(diffValue)}
                        <span>%</span>
                      </>
                    ) : (
                      <>
                        {localizeNumber(prevValue)}
                        {showUnit && <span>{unit}</span>}
                      </>
                    )}
                  </StatusArrow>
                )}
              </div>
            )}
            {tags?.length > 0 && (
              <div className="Status__tags">
                {tags.map(({ name, value }) => (
                  <span className="Status__tag" key={name}>
                    {name}
                    {value && <strong>{value}</strong>}
                  </span>
                ))}
              </div>
            )}
          </Component>
        )}
        {caption && (
          <StatusCaption
            className="StatusCaption"
            title={showDescription && caption.title}
            icon={caption.icon}
            type={caption.type}
            hideValue={caption.hideValue}
            color={caption.color || color}
            variant={styleProps.variant}
            description={description}
            iconSize={iconSize}
          />
        )}
      </StatusContainer>
      <WidgetLoader {...loading} spinner={false} />
      <WidgetError error={error} />
      {inlineErrors.length > 0 && <InlineErrors errors={inlineErrors} />}
    </StatusStyled>
  )
}

export default Ui
