import { setContext } from '@apollo/client/link/context'

/**
 * Putting the locale into the headers to be able to translate the backend reponse.
 */
const localeContext = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const customerData = localStorage.getItem('customer')
  // return the headers to the context so httpLink can read them
  if (!customerData) {
    return { headers }
  }

  const customer = JSON.parse(customerData)

  const locale = customer?.locale || 'en-US'

  return {
    headers: {
      ...headers,
      locale,
    },
  }
})

export default localeContext
