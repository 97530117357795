export default {
  'form.submit': 'Sauvegarder',
  'form.reset': 'Réinitialiser',
  'form.field.error.required': 'Ce champ est obligatoire',
  'form.tag.error.required': 'Une valeur pour ce tag est requise',
  'form.validation.unique': `Cette valeur n'est pas unique`,
  'form.validation.containsErrors': "Résolvez les erreurs avant d'économiser",

  // Specific
  'form.email.alreadyExists': " est déjà utilisé par quelqu'un.",
  'form.email.alreadyInvited': " est déjà utilisé par quelqu'un.",

  // Labels
  'form.label.name': 'Nom',
  'form.label.email': 'Email',
  'form.label.emails': 'Addresse(s) e-mail',
  'form.label.phone': 'Numéro de téléphone',
  'form.label.surname': 'Nom de famille',
  'form.label.firstname': 'Prénom',
  'form.label.roles': 'Les rôles',
  'form.label.fixedPermissions': 'Autorisations fixes',
  'form.label.optional': '(Facultatif)',
  'form.label.street': 'Rue',
  'form.label.streetNumber': 'N°',
  'form.label.postalCode': 'Code postal',
  'form.label.city': 'Ville',
  'form.label.country': 'Pays',
  'form.label.vatNumber': 'N° TVA',
  'form.label.website': 'Site web',
  'form.label.address': 'Adresse',
  'form.label.administrator': 'Admin',
  'form.label.createdAt': 'Date création',
  'form.label.updatedAt': 'Dernière mise à jour',
  'form.label.hasDatapoints': 'Database',
  'form.label.isActive': 'Actif',
  'form.label.deactivated': 'Désactivé',
  'form.label.isDealer': 'Est revendeur',
  'form.label.dealer': 'Revendeur',
  'form.label.logo': 'Logo',
  'form.label.noImage': "Pas d'image",
  'form.label.yes': 'Oui',
  'form.label.no': 'Non',
  'form.label.adminFirstName': 'Prénom admin',
  'form.label.adminLastName': 'Nom admin',
  'form.label.adminMail': 'Adresse e-mail admin',
  'form.label.mobile': 'Numéro de téléphone portable',
  'form.label.avatar': 'Image profil',
  'form.label.customers': 'Clients',
  'form.label.dataLimit': 'Limite de données',
  'form.label.smsLimit': 'Limite SMS',
  'form.label.energyClassification': 'Energy Classification',
  'form.label.reportingYear': 'Reporting Year',
  'form.label.legalEntity': 'Legal Entity',
  'form.label.customerCode': 'Customer Code',
  'form.label.internalId': 'Internal Ref.',
  'form.label.customerMail': 'Adresse e-mail Entreprise',
  'form.label.numericOnly': 'Caractères numériques uniquement',
  'form.label.noZeroesAfterPlus': '[+][préfixe international] [inter-urbain] [numéro national]',

  // Generic
  'form.input.required': ' est un champ obligatoire.',
  'form.input.invalid': ' est une valeur invalide.',
  'form.select.required': 'Vous devez sélectionner une valeur',
  'form.select.requiredOne': 'Vous devez sélectionner au moins une valeur.',
  'form.password.minimum': 'Longueur minimale du mot de passe: 8 caractères',
  'form.password.maximum': 'Longueur maximale du mot de passe: 50 caractères',
  'form.password.regex': 'Le mot de passe nécessite au moins 1 lettre majuscule et 1 chiffre',
  'form.password.match': 'Les mots de passe ne correspondent pas',
  'form.dropHereToUpload': 'Déposez ici pour télécharger',
  'form.uploading': 'Téléchargement...',
  'form.dropAfileToReplaceImage': "Déposer un fichier pour remplacer l'image",
  'form.moreInfo': "Plus d'infos",
  'form.acceptCookies': 'Accepter les cookies',
  'form.iAgree': "Je suis d'accord",

  // Validation
  'form.validation.maxLength': 'La longueur maximale de ce champ est de ',
  'form.validation.characters': 'caractères.',
  'form.validation.noUserWithThisEmailAddress':
    "Il n'y a pas d'utilisateur avec cette adresse e-mail.",

  'form.field.education.primary_education': 'PRIMARY EDUCATION',
  'form.field.education.basic_education': 'BASIC EDUCATION',
  'form.field.education.secondary_education': 'SECONDARY EDUCATION',
  'form.field.education.college_graduate': 'COLLEGE GRADUATE',
  'form.field.education.bachelor': 'BACHELOR',
  'form.field.education.master': 'MASTER',
  'form.field.education.doctor': 'DOCTOR',
}
