export default {
  all: 'All',
  active: 'Active',
  hideInactive: 'Hide inactive',
  action: 'Action',
  clickToCopy: 'Click to copy',
  copiedToClipboard: 'The value is copied to your clipboard',
  duplicate: 'Duplicate',
  deepDuplicate: 'Duplicate with children',
  moveTo: 'Move to',
  remarks: 'Remarks',
  delete: 'Delete',
  confirm: 'Confirm',
  save: 'Save',
  clear: 'Clear',
  cancel: 'Cancel',
  continue: 'Continue',
  enabled: 'Enabled',
  enable: 'Enable',
  disable: 'Disable',
  create: 'Create',
  deleteSuccess: '{name} has been successfully removed',
  isRequired: ' is required',
  confirmMessage: 'Are you sure?',
  confirmVehicleDisable: 'Are you sure you want to disable the vehicle?',
  deleteConfirmationMessage: 'Are you sure you want to delete ',
  unsubscribeConfirmationMessage: 'Are you sure you want to unsubscribe to dashboard group ',
  deleteRenewMessage: 'Are you sure you want to renew ',
  deleteItemSuccess: 'Item was succesfully deleted',
  deleteItemError: 'Unable to delete the item',
  deleteItemViolationError: 'Unable to delete the item. It is linked to other records.',
  duplicateConfirmationMessage: 'Are you sure you want to duplicate ',
  duplicateItemSuccess: '{name} was succesfully duplicated',
  duplicateItemError: 'Unable to duplicate the {name}',
  duplicateItemStart: 'Duplicating of {name} has started',
  updateItemSuccess: 'Item successfully saved',
  updateItemError: 'Unable to save the item',
  updateSuccess: ' has been successfully updated',
  insertItemSuccess: 'Item successfully created',
  insertItemError: 'Unable to create the item',
  confirmDiscardChanges: 'Are you sure you want to discard your changes?',
  movedItemSuccess: 'Moved {name} successfully',
  pasteItemSuccess: 'Pasted {name} successfully',
  pasteItemError: 'Something whent wrong while pasting {name}',
  selected: 'Selected',
  loadnig: 'Loading',
  multiply: 'Multiply',
  devide: 'Divide',
  copyFfDefaults: 'Import defaults',
  data: 'Data',
  historical: 'Historical',
  transparancy: 'Transparency',
  benchmark: 'Benchmark',
  date: 'Date',
  activationDate: 'Activation date',
  export: 'Export',
  export_xlsx: 'Export XLSX',
  export_docx: 'Export DOCX',
  site: 'Site',
  building: 'Building',
  country: 'Country',
  department: 'Department',
  dateOfBirth: 'Date of birth',
  yearOfBirth: 'Year of birth',
  gender: 'Gender',
  highestDegreeOfEducation: 'Highest degree of education',
  filter: 'Filter',
  clearFilter: 'Clear filter',
  download: 'Download',
  reload: 'Reload',
  add: 'Add',
  import: 'Import',
  vehicle: 'Vehilcle',
  vehicleType: 'Vehicle type',
  power: 'Power',
  fuelConsumptionCombined: 'Fuel consumption combined',
  energyConsumptionCombined: 'Energy consumption combined',
  co2Emission: 'CO2 emission',
  maxLoadCapacity: 'Max load capacity',
  fuelType: 'Fuel type',
  vehicleFuelType: 'Vehicle fuel type',
  organisation: 'Organisation',
  organisationalSturctureType: 'Organisational Structure Type',
  entity: 'Entity',
  value: 'Value',
  internalId: 'Internal ID',
  constructionYear: 'Construction year',
  consumption: 'Consumption',
  mileage: 'Mileage',
  consumptionInputPreference: 'Consumption input preference',
  createNewVersion: 'Create new version',
  title: 'Title',
  subTitle: 'Subtitle',
  default: 'Default',
  contract: 'Contract',
  key: 'Key',
  items: 'Items',
  description: 'Description',
  order: 'Order',
  parent: 'Parent',
  label: 'Label',
  color: 'Color',
  page: 'Page',
  row: 'Row',
  name: 'Name',
  product: 'Product',
  productionRelated: 'Production Related',
  finishingLevel: 'Finishing Level',
  supplier: 'Supplier',
  reportingPeriod: 'Reporting Period',
  totalEmission: 'Total Emission',
  extra: 'Extra',
  datapoint: 'Datapoint',
  settings: 'Settings',
  equipment: 'Equipment',
  exportAsExcel: 'Export as Excel',
  exportAsWord: 'Export as Word',
  yes: 'Yes',
  no: 'No',
  lastValue: 'Last Value',
  direction: 'Direction',
  category: 'Category',
  selectCategory: 'Select category',
  consumer: 'Consumer',
  activity: 'Activity',
  transportMode: 'Transport mode',
  transportLoadType: 'Transport load type',
  travelMethod: 'Travel method',
  typeLogistics: 'Type of logistics',
  image: 'Image',
  type: 'Type',
  createdAt: 'Created at',
  selectMultipleItems: 'Select multiple items',
  duplicateSelected: 'Duplicate {count} item(s) here',
  moveSelected: 'Move {count} item(s) here',
  bulkDuplicateItemsSuccess: 'Duplicated {count} item(s)',
  bulkMoveItemsSuccess: 'Moved {count} item(s)',
  maxFavouritesReached: 'Maximum of {amount} favourites is reached.',
  inCompleteForm: 'Not all mandatory fields are filled in',
  sortBy: 'Sort by',
  parentOrganisation: 'Parent organisation',
  childOrganisation: 'Child organisation',
  percentage: 'Percentage',
  financialControl: 'Financial control',
  jointFinancialControl: 'Joint financial control',
  operationalControl: 'Operational control',
  ownership: 'Ownership',
  info: 'Info',
  joint: 'Joint',
  equityShare: 'Equity share',
  equitySharePercentage: 'Equity share percentage',
  organisationDraftMode: 'You are in draft mode, select a date and save to exit draft mode.',
  percentageExceeds: 'Total percentage exceeds {value}%',
  greaterThen: 'Must be greater then {value}',
  franchisee: 'Franchisee',
  franchisor: 'Franchisor',
  lessor: 'Lessor',
  lessee: 'Lessee',
  investor: 'Investor',
  investee: 'Investee',
  useRestPercentage: 'Use the available {percentage}%',
  combinationWithFieldAlreadyExists: 'Combination with {field} already exists',
  environmentType: 'Environment type',
  distributed: 'Distributed',
  sharedOwnership: 'Shared ownership',
  remark: 'Remark',
  dateRequired: 'Date is required',
  totalExceedsAmount: 'The total exceeds {amount}',
  canNotBeTheSameAs: 'Can not be the same as {value}',
  details: 'Details',
  showDetails: 'Show details',
  consumptionValue: 'Consumption value',
  'consumptionValue.info': 'Enter value as a meter value (Off) or as a consumption value (On)',
  locked: 'Locked',
  open: 'Open',
  updateError: 'Unable to save your changes',
  startDate: 'Start date',
  endDate: 'End date',
  period: 'Period',
  status: 'Status',
  license: 'License',
  noModulesFound: 'No modules found',
  unlimited: 'Unlimited',
  min: 'Min',
  max: 'Max',
  timestamp: 'Timestamp',
  user: 'User',
  operation: 'Operation',
  customer: 'Customer',
  switchedToCustomer: 'Switch to {customer}',
  application: 'Application',
  version: 'Version',
  versions: 'Versions',
  deployedAt: 'Deployed at',
  shortcuts: 'Shortcuts',
  dashboard: 'Dashboard',

  boundaries: 'Boundaries',
  activities: 'Activities',
  ghgSettings: 'GHG settings',
  projectTeam: 'Project team',
  successfullySaved: 'Saved successfully.',
  squealThreshold: 'Squeal threshold',
  sendMail: 'Send mail',
  sendSms: 'Send SMS',
  sendVoiceMessage: 'Call mobile phone',
  sendVoiceFixedMessage: 'Call fixed phone',
  doNothing: 'No action',
  noFlowLogic: 'At least one condition is needed',
  if: 'If',
  then: 'Then',
  elseIf: 'Else if',
  else: 'Else',
  to: 'to',
  show: 'Show',

  'reportingYearLock.update.success': 'Reporting year lock is updated',

  'organisation.structure.state.update.success': 'State is saved',
  'organisation.structure.state.create.error': 'Error creating new organisation structure',
  'organisation.structure.state.update.error': 'Error updating organisation structure',
  'organisation.structure.state.update.error.percentage': 'Some percentages exceed 100%',
  'organisation.structure.state.edge.error.onlyOne': 'Only one relation possible',

  'organisation.env.state.create.success': 'New draft state is created',
  'organisation.env.state.create.error': 'Error creating new organisation envrionment state',
  'organisation.env.state.update.success': 'State is saved',
  'organisation.env.state.update.error': 'Error updating organisation structure',
  'organisation.env.state.update.error.percentage': 'Some percentages exceed 100%',

  'error.entity.samePresetExistsInSite':
    'An entity with the same preset already exists in this site. Only one entity of this type is allowed.',

  'noAccess.title': 'No access',
  'noAccess.subTitle': 'Sorry, you are not authorized to access this page.',

  // Entity
  'entity.type.department': 'Department',
  'entity.type.building': 'Building',
  'entity.type.site': 'Site',
  'entity.type.fuel': 'Fuel',
  'entity.type.fuelType': 'Fuel type',

  // Dashboard components
  'calendarComponent.unsavedChange': 'there are unsaved changes...',
  'calendarComponent.savedChanges': 'all changes saved',
  'calendarComponent.save': 'save',
  'connectivity.title': 'CONNECTIVITY',
  'connectivity.isOnline': 'is online',
  'connectivity.lastConnection': 'last connection',
  'connectivity.ago': 'ago',
  'graphComponent.presetControl': 'Preset',
  'graphComponent.startTimeControl.startTime': 'Start time',
  'graphComponent.endTimeControl.endTime': 'End time',
  'graphComponent.granularyControl.granularity': 'Granularity',
  'graphComponent.presetControl.thisDay': 'Today',
  'graphComponent.presetControl.linkedWidget': 'Linked widget',
  'graphComponent.presetControl.thisWeek': 'This week',
  'graphComponent.presetControl.thisMonth': 'This month',
  'graphComponent.presetControl.thisYear': 'This year',
  'graphComponent.presetControl.pastDay': 'Past 24 hours',
  'graphComponent.presetControl.past7Days': 'Past 7 days',
  'graphComponent.presetControl.past21Days': 'Past 21 days',
  'graphComponent.presetControl.past28Days': 'Past 28 days',
  'graphComponent.presetControl.previousWeek': 'Previous week',
  'graphComponent.presetControl.previousMonth': 'Previous month',
  'graphComponent.presetControl.previousYear': 'Previous year',
  'graphComponent.presetControl.week': 'Week',
  'graphComponent.presetControl.month': 'Month',
  'graphComponent.presetControl.year': 'Year',
  'graphComponent.presetControl.yesterday': 'Yesterday',
  'graphComponent.presetControl.dayBefore': 'Day before',
  'graphComponent.presetControl.thisDayLastWeek': 'This day last week',
  'graphComponent.presetControl.customPeriod': 'Custom period',
  'graphComponent.presetControl.customRange': 'Custom range',
  'graphComponent.presetControl.customDate': 'Custom date',
  'graphComponent.presetControl.none': 'None',
  'graphComponent.presetControl.start': 'Start',
  'graphComponent.presetControl.end': 'End',
  'graphComponent.rawDataControl.rawData': 'Raw Data',
  'graphComponent.tooltip.totals': 'Totals',
  'severityComponent.critical.criticalIssues': 'Critical issues',
  'severityComponent.severe.severeIssues': 'Severe issues',
  'severityComponent.warning.warnings': 'Warnings',
  'severityComponent.details': 'Details',
  'severityComponent.overview': 'Overview',
  'squealHeatmapComponent.heatMap.selectSqueal': 'Select a squeal to inspect',
  'squealOverviewComponent.acknowledge': 'Acknowledge',
  'squealOverviewComponent.viewHistory': 'View history',
  'squealOverviewComponent.startTime': 'Start time',
  'squealOverviewComponent.cost': 'Cost',
  'squealOverviewComponent.severity': 'Severity',
  'squealOverviewComponent.name': 'Name',
  'squealOverviewComponent.confirmAcknowledge.title':
    'Are you sure you wish to acknowledge the squeal?',
  'squealOverviewComponent.confirmAcknowledge.subtitle':
    'It will no longer show unless the malfunction occurs again.',
  'squealRemarksComponent.cancel': 'Cancel',
  'squealRemarksComponent.confirm': 'Confirm',
  'squealRemarksComponent.justifyAck': 'Justify acknowledgement of squeal',
  'squealRemarksComponent.nothingToRemark': 'No remarks to be made for this squeal.',
  'squealRemarksComponent.remarkInput.period': 'Period',
  'squealRemarksComponent.remarkInput.peakVisitors': 'Peak number of visitors',
  'squealRemarksComponent.remarkInput.peakOccurredOn': 'Peak occurred on',
  'squealRemarksComponent.remarkInput.remarkRequired': 'Please provide a remark ',
  'squealRemarksComponent.remarkInput.remark': 'Remark...',
  'squealTrendComponent.selectSqueal': 'Select a heatmap entry to view the squeals trend',
  'consumptionExport.reportSent': 'Report sent',
  'consumptionExport.reportFailed': `Report could not be generated`,
  'dashboard.error.title': 'This dashboard is not working',
  'dashboard.error.subTitle': 'We are unable to display this dashboard due to an error.',

  'widget.error.title': 'Error',
  'widget.error.subTitle': `Unable to display widget`,

  //AgEventLog Widget
  'widget.agEventLog.header.beginTime': 'Begin',
  'widget.agEventLog.header.endTime': 'End',
  'widget.agEventLog.header.name': 'Name',
  'widget.agEventLog.header.tag': 'Tag',
  'widget.agEventLog.header.lastValue': 'Last value',
  'widget.agEventLog.header.inUse': 'In use',
  'widget.agEventLog.header.error': 'Error',
  'widget.agEventLog.header.alarm': 'Alarm',
  'widget.agEventLog.header.duration': 'Duration',
  'widget.agEventLog.currentState': 'Current value',

  // Settings components
  'entityTypeManager.entityTypeConfig.name': 'Name',
  'entityTypeManager.entityTypeConfig.marker': 'Marker',
  'entityTypeManager.entityTypeConfig.tag': 'Tag',
  'entityTypeManager.entityTypeConfig.field': 'Field',
  'entityTypeManager.entityTypeConfig.confirm': 'Confirm',
  'entityTypeManager.entityTypeConfig.saveChanges': 'Save changes',
  'entityTypeManager.entityTypeConfig.discardChanges': 'Discard changes',
  'entityTypeManager.entityTypeConfig.translations': 'Translations (optional)',
  'entityTypeManager.entityTypeConfig.unsavedChanges': 'There are unsaved changes',
  'entityTypeManager.entityTypeConfig.unsavedChangesVerbose':
    'Do you want to discard or save the changes to the entity preset?',
  'entityTypeManager.entityTypeConfig.englishRequired': 'English name is required',
  'entityTypeManager.entityTypeConfig.required': 'Required',
  'entityTypeManager.entityTypeConfig.tagName': 'Tag name',
  'entityTypeManager.entityTypeConfig.tagType': 'Tag type',
  'entityTypeManager.entityTypeConfig.typeRequired': 'Tag type is required',
  'entityTypeManager.entityTypeConfig.nameRequired': 'Tag name is required',
  'entityTypeManager.entityTypeConfig.discardedChanges': 'Discarded changes',
  'entityTypeManager.entityTypeConfig.invalidPreset': "Entity preset is not valid, couldn't save",
  'entityTypeManager.entityTypeConfig.createEntity': 'Create new entity',
  'entityTypeManager.entityTypeConfig.updateEntity': 'Update entity',
  'entityTypeManager.entityTypeConfig.confirmDeleteEntity': 'Confirm delete',
  'entityTypeManager.entityTypeConfig.createdEntity': 'Successfully saved new entity',
  'entityTypeManager.entityTypeConfig.updatedEntity': 'Successfully updated entity',
  'entityTypeManager.entityTypeConfig.deletedEntity': 'Successfully deleted entity',
  'entityTypeManager.entityTypeConfig.entityType': 'Entity type',
  'entityTypeManager.entityTypeConfig.entityTypeSite': 'Site',
  'entityTypeManager.entityTypeConfig.entityTypeBuilding': 'Building',
  'entityTypeManager.entityTypeConfig.entityTypeFloor': 'Floor',
  'entityTypeManager.entityTypeConfig.entityTypeSpace': 'Space',
  'entityTypeManager.entityTypeConfig.entityTypeRoom': 'Room',
  'entityTypeManager.entityTypeConfig.entityTypeEquipment': 'Equipment',
  'entityTypeManager.entityTypeConfig.entityTypeDatapoint': 'Datapoint',
  'entityTypeManager.entityTypeConfig.deleteTheFollowing':
    'Do you really wish to delete the following?',
  'entityTypeManager.entityTypeConfig.ofType': 'of type',
  'entityTypeManager.entityTypeConfig.unnamed': 'New datapoint',
  'entityTypeManager.entityTypeConfig.titleDatapointConfigurator': 'Datapoint configuration',
  'entityTypeManager.entityTypeConfig.datapointName': 'Datapoint name',
  'entityTypeManager.tagsForm.noWhitespaces': 'No whitespaces allowed',
  'entityTypeManager.tagsForm.reservedKeyword': 'Reserved keyword',
  'entityTypeManager.tagsForm.noDuplicateTags': 'No duplicate tags allowed',

  'datapointsOverview.exportCSV': 'Download all datapoints as CSV file',
  'datapointsOverview.manageEntities': 'Manage entities',
  'datapointsOverview.reload': 'Reload all datapoints',
  'datapointsOverview.editDatapoint': 'Edit datapoint',
  'datapointsOverview.deleteDatapoint': 'Delete datapoint',
  'datapointsOverview.clearFilters': 'Clear all filters and column sorting',

  'entityConfigurator.entityForm.entityName': 'Name',
  'entityConfigurator.entityForm.nameRequired': 'Name is required',
  'entityConfigurator.entityForm.entityPreset': 'Entity preset',
  'entityConfigurator.entityForm.presetRequired': 'Please select a preset',
  'entityConfigurator.entityForm.tagValueRequired': 'Tag value is required',
  'entityConfigurator.entityForm.tagValue': 'Tag value',
  'entityConfigurator.entityForm.updatedEntity': 'was succesfully updated',
  'entityConfigurator.entityForm.createdEntity': 'was succesfully created',
  'entityConfigurator.entityForm.unitRequired': 'Please select a unit',
  'entityConfigurator.entityForm.unit': 'Unit',

  // User Overview
  'userOverview.admin': 'Administrator',
  'userOverview.noData': 'No data',

  // Role Overview
  roleOverview: 'Role overview',
  'roleOverview.role': 'Role',
  'roleOverview.permissions': 'Permissions',
  'roleOverview.providesAccess': 'Provides access to',
  'roleOverview.and': 'and',
  'roleOverview.noPermissions': 'No permissions',
  'roleOverview.view': 'View',
  'roleOverview.edit': 'Edit',
  'roleOverview.invalid': 'Invalid permission',
  'roleOverview.canNotDeleteAdminRole': 'Admin role can not be deleted',

  // Role Modal
  'roleModal.addPermission': 'Add permission',
  'roleModal.accessibleDatapoints': 'Accessible datapoints for',
  'roleModal.roleName': 'Role name',
  'roleModal.createdRole': 'was successfully created',
  'roleModal.updatedRole': 'was successfully updated',
  'roleModal.roleNameRequired': 'Role name is required',
  'roleModal.editRole': 'Edit role',
  'roleModal.newRole': 'New role',

  // Customer overview
  'customerOverview.create': 'New customer',

  // Permissions Modal
  'permissionsModal.step1': 'Step 1',
  'permissionsModal.step1description': 'Provide access to...',
  'permissionsModal.step1ToolTip':
    'Selecting an entity in below tree view, will grant access to that entity and all its children. Available tags in step 2 will update upon selecting an entity.',
  'permissionsModal.step2': 'Step 2',
  'permissionsModal.step2description': 'Where tags and markers...',
  'permissionsModal.step2ToolTip':
    'Selecting tags from the list below will limit this permission to give access to those entities that have all of the selected tags. Selecting more tags will therefore result in a smaller set of datapoints the permission will give access to, which is reflected in step 3.',
  'permissionsModal.step3': 'Step 3',
  'permissionsModal.step3description': 'Preview datapoints',
  'permissionsModal.step3ToolTip':
    'Selecting an entity and tags will result in a set of datapoints to which this permission will give access to. These datapoints can be previewed below.',

  'permissionsModal.searchTags': 'Search for tags...',
  'permissionsModal.availableTags': 'Available tags for',
  'permissionsModal.selectedForPermission': 'Selected for permission',
  'permissionsModal.updatingPermission': 'Updating permission',
  'permissionsModal.creatingPermission': 'Creating permission',
  'permissionsModal.updateFailed': 'Updating permission failed',
  'permissionsModal.creationFailed': 'Creating permission failed',
  'permissionsModal.updateSuccess': 'Permission successfully updated',
  'permissionsModal.creationSuccess': 'Permission successfully created',
  'permissionsModal.editPermission': 'Edit permission',
  'permissionsModal.newPermission': 'New permission',
  'permissionsModal.for': 'for',
  'permissionsModal.permission': 'Permission',
  'permissionsModal.companyEntities': 'Company entities',
  'permissionsModal.externalEntities': 'External entities',

  // Terms of use
  'title.cookies': 'Cookies',

  //Support en Contact Modal
  'support.title': 'Support',
  'contact.title': 'Contact',
  'support.succes.message': 'Message has been succesfully sent',
  'support.error.message': 'Error while sending message',
  'label.name': 'Name',
  'label.surName': 'Surname',
  'label.email': 'Email',
  'label.phone': 'Phone number',
  'label.title': 'Title',
  'label.description': 'Description',
  'submit.form': 'Submit',
  'cancel.form': 'Cancel',
  'support.headertext':
    'Please fill in the form below and we will contact you as soon as possible regarding your support enquiry.',
  'contact.headertext':
    'Please fill in the form below and we will contact you as soon as possible regarding your enquiry.',

  // Overview pagenames
  'customers.overviewPage.name': 'Customer overview',
  'datapoints.overviewPage.name': 'Datapoint overview',
  'users.overviewPage.name': 'User overview',

  // Tag Container
  'tagContainer.noMoreTagsFor': 'No more tags available for',
  'tagContainer.noTagsPleaseSelect': 'Please select an entity to show available tags',

  // Dashboard modal
  'menu.dashboardForm.dashboardName': 'Dashboard name',
  'menu.dashboardForm.nameRequired': 'Dashboard name is required',
  'menu.dashboardForm.iconRequired': 'Dashboard icon is required',
  'menu.dashboardForm.positionDashboard': 'Dashboard position',
  'menu.dashboardForm.positionHelp':
    '(Optional) Select a dashboard on the right to make this new dashboard a child dashboard (sub category) of the selected dashboard. Leave empty to make a dashboard that has no hierarchical relation to other dashboards.',
  'menu.dashboardForm.positionPlaceholder': '(Optional) Select parent dashboard',
  'menu.dashboardForm.favouriteDashboard': 'Add to favourites',
  'menu.dashboardForm.icon': 'Dashboard icon',
  'menu.dashboardForm.creationFailed': 'Dashboard creation failed',
  'menu.dashboardForm.creationSuccess': 'Dashboard creation succeeded',
  'menu.dashboardForm.emptyTitle': 'A title should consist of atleast one character',
  'menu.dashboardForm.updateFailed': 'Updating the dashboard has failed',
  'menu.dashboardForm.dragFailed': 'Updating the dashboards has failed',
  'menu.dashboardForm.dragSuccess': 'Dashboard updates succeeded',
  'menu.dashboardForm.updateSuccess': 'Dashboard has been successfully updated',
  'menu.dashboardForm.removeFailed': 'Dashboard removal has failed',
  'menu.dashboardForm.removeSuccess': 'Dashboard removal succeeded',
  'menu.dashboardTree.createTopLevelItem': 'Create a top-level dashboard',
  'menu.settings': 'Settings',

  // Confidential information modal
  'title.confidential.information': 'Data processing, privacy and website use',
  'title.confidential.informationShort': 'General policy',

  // Terms and Conditions
  'termsAndConditions.title': 'General Terms and Conditions',

  // compliance Modal
  'title.compliance': 'Compliance',

  // Units
  unitsOverview: 'Unit overview',
  'unitEdit.editUnit': 'Edit unit',
  'unitEdit.updateFailed': 'Unit update failed',
  'unitEdit.updateSuccess': 'Unit was successfully updated',
  'unitCreate.newUnit': 'New unit',
  'unitCreate.creationFailed': 'Unit creation failed',
  'unitCreate.creationSuccess': 'Unit successfully created',
  'unitForm.unitName': 'Unit name',
  'unitForm.nameRequired': 'Please provide a unit name',
  'unitForm.unitFormat': 'Unit format',
  'unitForm.unitType': 'Type',

  // Data table
  'dataTable.reload': 'Reload all',
  'dataTable.manage': 'Manage',
  'dataTable.exportCSV': 'Download to CSV',
  'dataTable.edit': 'Edit',
  'dataTable.delete': 'Delete',
  'dataTable.close': 'Close',
  'dataTable.cancel': 'Cancel',
  'dataTable.save': 'Save',
  'dataTable.saveAndClose': 'Save and close',
  'dataTable.new': 'Add new',
  'dataTable.clearFilters': 'Clear all filters and column sorting',
  'dataTable.lastValue': 'Last value',
  'datapointsOverview.datapointSingle': 'Datapoint',
  'datapointsOverview.datapointPlural': 'Datapoints',
  'usersOverview.userSingle': 'User',
  'usersOverview.userPlural': 'Users',

  // Layout components
  'settings.themeSettings': 'Theme Settings',
  'settings.collapsedMenu': 'Collapsed menu',
  'settings.menuShadow': 'Menu shadow',
  'settings.lightTheme': 'Light theme',
  'settings.borderlessCards': 'Borderless cards',
  'settings.squaredBorders': 'Squared borders',
  'settings.fixedWidth': 'Fixed Width',
  'topBar.profileMenu.hello': 'Hello {name}',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.theme': 'Theme',
  'topBar.profileMenu.editProfile': 'Edit profile',
  'topBar.profileMenu.logout': 'Logout',
  'topBar.profileMenu.preferences': 'Preferences',
  'topBar.profileMenu.advanced': 'Advanced',
  'menu.favourites': 'Favourites',
  'menu.dashboardCreate': 'Create Dashboard',
  'menu.dashboards': 'Dashboards',
  'menu.dataEntryCreate': 'Create data entry dashboard',
  'menu.dashboards.edit': 'Manage dashboards',
  'menu.dashboard.shareDashboard': 'Share dashboards',
  'menu.dashboard.share': 'Share',
  'menu.dashboard.shareFailed': 'Dashboard sharing failed',
  'menu.dashboard.shareSuccess': 'Dashboard(s) were successfully shared',
  'menu.dashboard.newDashboard': 'Manage dashboards',
  'menuBar.logoDropdown.currentCustomer': 'Current customer',
  'layout.globalTimeSelection': 'Global time control',
  'layout.sectionTimeSelection': 'Section time control',

  // Share Dashboard
  'shareDashboard.selectDashboards': 'Select dashboards',
  'shareDashboard.shareWith': 'Share dashboards with',
  'shareDashboard.permissionLevel': 'Permission level',
  'shareDashboard.allowEdit': 'Master dashboard group',
  'shareDashboard.affectedUsers': 'Subscribed users',
  'shareDashboard.usersByMailAddress': 'Users via e-mail address',
  'shareDashboard.knownUsers': 'Known users',

  // Delete modal
  'modal.delete.success': 'Successfully deleted',
  'modal.delete.error': 'Something went wrong',

  // Invite page
  'invite.welcomeToAviary': 'Welcome to Canary',

  // General
  'general.unsavedChanges': 'There are unsaved changes',
  'general.savedChanges': 'All changes saved',
  'general.noChangesToSave': 'No changes to save',
  'general.save': 'Save',
  'general.cancel': 'Cancel',
  'general.yes': 'Yes',
  'general.no': 'No',
  'general.someValuesAreToHeigh': 'Some values are too high',
  'general.incorrectPassword': 'Password is incorrect',
  'general.inputOwnPassword': 'Please input your own password',

  // Languages
  'language.dutch': 'Dutch',
  'language.english': 'English',
  'language.french': 'French',
  'language.nl-NL': 'Dutch',
  'language.en-US': 'English',
  'language.fr-FR': 'French',

  // Report
  'report.create': 'Create report',
  'report.site': 'Site',
  'report.address': 'Address',
  'report.contact': 'Contact',
  'report.creationDate': 'Creation date',
  'report.scraper': 'Scraper',
  'report.phone': 'Phone',
  'report.openingTimes': 'Opening hours',
  'report.visitorsInformation': 'Visitors information',
  'report.entered': 'ENTERED',
  'report.exited': 'EXITED',
  'report.max': 'MAX',
  'report.heatmap': 'Heatmap',
  'report.squeals': 'Squeals',
  'report.noSqueals': 'No Squeals',
  'report.none': 'None',
  'report.acknowledged': 'Acknowledged on',
  'report.notAcknowledged': 'Not acknowledged',
  'report.remark': 'Remark Squeal',
  'report.footer': 'This report was generated by Aviary® - a Canary® by product for',
  'report.waitForLoading': 'Please wait for your report to be created',

  // Error handling
  'global.message.somethingWentWrong': 'Something went wrong',

  // Calendar months
  'month.1': 'January',
  'month.2': 'February',
  'month.3': 'March',
  'month.4': 'April',
  'month.5': 'May',
  'month.6': 'June',
  'month.7': 'July',
  'month.8': 'August',
  'month.9': 'September',
  'month.10': 'October',
  'month.11': 'November',
  'month.12': 'December',

  // missing
  Catches: 'Catches',

  // General Component Captions
  'componentCaptions.noMarkers': 'No markers',
  'componentCaptions.noTags': 'No tags',
  'componentCaptions.noDatapoints': 'No datapoints',
  'componentCaptions.noRules': 'No rules',
  'componentCaptions.noOrganisations': 'No organisations',
  'componentCaptions.noResults': 'No results',
  'componentCaptions.searchToSelect': 'Search to Select',
  'componentCaptions.noConditions': 'No conditions',
  'componentCaptions.addCondition': 'Add condition',

  'organisation.structure.type.EQUITY': 'Equity/Fin. Ctrl/Op. Ctrl',
  'organisation.structure.type.FRANCHISE': 'Franchise',
  'organisation.structure.type.INVESTMENT': 'Investment',
  'organisation.structure.type.LEASING': 'Leasing',

  'organisation.env.bulkUpdateWarning': 'You are about to update all items in this group.',

  'organisation.env.type.ASSETS': 'Assets',
  'organisation.env.type.VEHICLES': 'Vehicles',
  'organisation.env.type.UPSTREAM': 'Upstream/Downstream Activities',

  'organisation.environments.type.CORE': 'Core',
  'organisation.environments.type.FRANCHISE': 'Franchise',
  'organisation.environments.type.LEASING': 'Leasing',

  'reportingYear.status.INCOMPLETE': 'Incomplete',
  'reportingYear.status.COMPLETE': 'Complete',
  'reportingYear.status.IN_PROGRESS': 'In progress',

  'reportingYear.datepicker.info': 'No data-entry allowed for reporting-periods that are locked.',

  'search.goto.placeholder': 'Search for pages, fixed files, dashboards, ...',
  'search.switchCustomer.placeholder': 'Search for customer to switch to',
  'search.noResultFound': 'No results found',

  'shortcut.GOTO': 'Go to',
  'shortcut.SWITCH_CUSTOMER': 'Switch customer',
  'shortcut.VERSIONS': 'Versions',
  'shortcut.CHEATSHEET': 'Cheatsheet',
  'shortcut.CLOSE': 'Close',

  'file.type.images': 'Images',
  'file.type.documents': 'Documents',

  'energyContracts.warning.missingContractsItems': '{count} items',
  'energyContracts.warning.missingContracts': 'are missing a contract',
  'energyContracts.warning.missingContractItem': 'An item',
  'energyContracts.warning.missingContract': 'is missing a contract',
}
